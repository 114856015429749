<template>
	<v-card class="operations-visit-idletime">
		<v-container>
			<v-row align="center">
				<v-col cols="11">
					<span class="operations-visit-idletime__title">{{ visit.vesselimo + ' - ' + visit.vesselname }}</span>
				</v-col>
				<v-col cols="1">
					<v-btn icon ripple @click="close()">
						<v-icon>far fa-times</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-divider></v-divider>
			<v-row dense>
				<v-col cols="12">
					<operations-visit-idle-time-content :visit="visit" :stop="visit.stops[0]" />
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import OperationsVisitIdleTimeContent from './OperationsVisitIdleTimeContent.vue';

export default {
  components: { OperationsVisitIdleTimeContent },
	props: {
		visit: {
			type: Object,
			required: true
		}
	},
	methods: {
		close() {
			this.$emit('close');
		}
	}
};
</script>

