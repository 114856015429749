<template>
	<div class="operations-countdown">
		<v-icon small>far fa-sync</v-icon>
		<span>{{ nextRefreshLabel }}</span>
	</div>
</template>

<script>
export default {
	data() {
		return {
			count: 300, // seconds
			counter: null,
			nextRefreshTimer: '05:00'
		};
	},
	computed: {
		nextRefreshLabel() {
			return this.nextRefreshTimer;
		}
	},
	mounted() {
		this.startTimer(300);

		this.$puiEvents.$on('operations-navigationDrawer-show', () => {
			clearInterval(this.counter);
		});
		this.$puiEvents.$on('operations-navigationDrawer-hide', () => {
			this.startTimer(this.count);
		});
	},
	destroyed() {
		clearInterval(this.counter);
		this.$puiEvents.$off('operations-navigationDrawer-show');
		this.$puiEvents.$off('operations-navigationDrawer-hide');
	},
	methods: {
		checkLength(i) {
			if (i < 10) {
				i = '0' + i;
			}
			return i;
		},
		startTimer(countValue) {
			this.count = countValue;
			this.counter = setInterval(this.timer, 1000);
		},
		timer() {
			this.count = this.count - 1;
			const minutes = this.checkLength(Math.floor(this.count / 60));
			const seconds = this.checkLength(this.count - minutes * 60);
			if (this.count < 0) {
				clearInterval(this.counter);
				return;
			}
			this.nextRefreshTimer = minutes + ':' + seconds;
			if (this.count === 0) {
				clearInterval(this.counter);
				if (this.$store.getters.getToken !== null) {
					this.$puiEvents.$emit('operationscountdown_reload');
					this.startTimer(300);
				}
			}
		}
	}
};
</script>

<style lang="postcss">
.operations-countdown {
	position: fixed;
	width: 103px;
	height: 44px;
	right: 24px;
	bottom: 12px;

	padding-top: 11px;
	padding-left: 17px;

	/* Neutral / N-0 */
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.03);
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
	border-radius: 8px;

	& .v-icon {
		margin-right: 10px;
	}
	& span {
		color: #6f7480;
	}
}
</style>
