<template>
	<v-container class="pa-3">
		<v-row class="pt-3">
			<v-col cols="12">
				<operations-visit-detail-performance-metrics :metrics="metrics"></operations-visit-detail-performance-metrics>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import OperationsVisitDetailPerformanceMetrics from './OperationsVisitDetailPerformanceMetrics.vue';
import DifferenceMixin from '@/mixins/HHmmDifferenceMixin';

export default {
	components: { OperationsVisitDetailPerformanceMetrics },
	mixins: [DifferenceMixin],
	props: {
		visit: {
			type: Object,
			required: true
		},
		stopSelected: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			metrics: []
		};
	},
	watch: {
		stopSelected() {
			this.fillMetrics(this.stopSelected);
		}
	},
	mounted() {
		this.fillMetrics(this.stopSelected);
	},
	methods: {
		fillMetrics(stop) {
			this.metrics = [];

			if (!stop.isanchorage) {
				// Vessel Arrival Process = AFA - APS
				const vap = this.getDifference(this.visit.aps, stop.afa);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.vap.name'),
					description: this.$t('operationsvisitdetailperformance.vap.description'),
					value: this.formatDifference(vap)
				});

				// Arrival Piloting Time = AFA - PBA
				const apt = this.getDifference(stop.pba, stop.afa);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.apt.name'),
					description: this.$t('operationsvisitdetailperformance.apt.description'),
					value: this.formatDifference(apt)
				});

				// Arrival Mooring Time = AFA - FLA
				const amt = this.getDifference(stop.fla, stop.afa);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.amt.name'),
					description: this.$t('operationsvisitdetailperformance.amt.description'),
					value: this.formatDifference(amt)
				});

				// Arrival IDLE Time = FCL - AFA
				const ait = this.getDifference(stop.afa, stop.fcl);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.ait.name'),
					description: this.$t('operationsvisitdetailperformance.ait.description'),
					value: this.formatDifference(ait)
				});

				// Departure IDLE Time = ATD - LCL
				const dit = this.getDifference(stop.lcl, stop.atd);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.dit.name'),
					description: this.$t('operationsvisitdetailperformance.dit.description'),
					value: this.formatDifference(dit)
				});

				// Vessel Departure Process = POF - RTS
				const vdp = this.getDifference(stop.rts, this.visit.pof);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.vdp.name'),
					description: this.$t('operationsvisitdetailperformance.vdp.description'),
					value: this.formatDifference(vdp)
				});

				// Departure Piloting Time = POF - PBD
				const dpt = this.getDifference(stop.pbd, this.visit.pof);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.dpt.name'),
					description: this.$t('operationsvisitdetailperformance.dpt.description'),
					value: this.formatDifference(dpt)
				});

				// Departure Mooring Time = LLA - PBD
				const dmt = this.getDifference(stop.pbd, stop.lla);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.dmt.name'),
					description: this.$t('operationsvisitdetailperformance.dmt.description'),
					value: this.formatDifference(dmt)
				});
			} else {
				// Vessel Arrival Process = IAS - APS
				const vap = this.getDifference(this.visit.aps, stop.ias);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.vapa.name'),
					description: this.$t('operationsvisitdetailperformance.vapa.description'),
					value: this.formatDifference(vap)
				});

				// Arrival Piloting Time = IAS - PBA
				const apt = this.getDifference(stop.pba, stop.ias);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.apta.name'),
					description: this.$t('operationsvisitdetailperformance.apta.description'),
					value: this.formatDifference(apt)
				});

				// Vessel Departure Process = POF - RTS
				const vdp = this.getDifference(stop.rts, this.visit.pof);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.vdp.name'),
					description: this.$t('operationsvisitdetailperformance.vdp.description'),
					value: this.formatDifference(vdp)
				});

				// Anchorage Time = EAS - IAS
				const at = this.getDifference(stop.ias, stop.eas);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.at.name'),
					description: this.$t('operationsvisitdetailperformance.at.description'),
					value: this.formatDifference(at)
				});

				// Departure Piloting Time = EAS - PBD
				const dpt = this.getDifference(stop.pbd, stop.eas);
				this.metrics.push({
					name: this.$t('operationsvisitdetailperformance.dpta.name'),
					description: this.$t('operationsvisitdetailperformance.dpta.description'),
					value: this.formatDifference(dpt)
				});
			}
		}
	}
};
</script>
