<template>
	<div>
		<operations-toolbar :showZoomIn="true"></operations-toolbar>
		<operations-visits-datatable
			v-if="visitsLoaded && visitHour"
			:visits="filteredVisits"
			:columns="columns"
			:visitHour="visitHour"
			:searchText="searchText"
			:slotsShipCount="slotsShipCount"
			:zoomIn="zoomIn"
		></operations-visits-datatable>
		<operations-navigation-drawer></operations-navigation-drawer>
		<operations-legend></operations-legend>
		<operations-countdown></operations-countdown>
		<operations-alive></operations-alive>
	</div>
</template>

<script>
import VisitsMixin from '@/mixins/VisitsMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import OperationsVisitsMixin from '@/mixins/OperationsVisitsMixin';

import OperationsToolbar from './operations/OperationsToolbar';
import OperationsVisitsDatatable from './operations/gantt/OperationsVisitsDatatable';
import OperationsNavigationDrawer from './operations/gantt/OperationsNavigationDrawer';
import OperationsLegend from './operations/OperationsLegend';
import OperationsAlive from './operations/OperationsAlive';
import OperationsCountdown from './OperationsCountdown';

export default {
	mixins: [VisitsMixin, DateTimeMixin, OperationsVisitsMixin],
	components: {
		OperationsToolbar,
		OperationsVisitsDatatable,
		OperationsNavigationDrawer,
		OperationsLegend,
		OperationsAlive,
		OperationsCountdown
	},
	data() {
		return {
			operationsdatatableKey: 0,
			zoomInToHoursPerColumn: {
				0: {
					// 1 day > 1h/Column
					numberOfColumns: 24,
					hoursPerColumn: 1,
					millisPerBloc: 450000 // 7min 30seg
				},
				1: {
					// 3 days > 3h/Column
					numberOfColumns: 24,
					hoursPerColumn: 3,
					millisPerBloc: 1350000 // 22min 30seg
				},
				2: {
					// 5 days > 6h/Column
					numberOfColumns: 20,
					hoursPerColumn: 6,
					millisPerBloc: 2700000 // 45min
				},
				3: {
					// 7 days > 8h/Column
					numberOfColumns: 21,
					hoursPerColumn: 8,
					millisPerBloc: 3600000 // 60min
				},
				4: {
					// 9 days > 12h/Column
					numberOfColumns: 18,
					hoursPerColumn: 12,
					millisPerBloc: 5400000 // 90min
				}
			},
			visitHour: null,
			columns: [],
			slotsShipCount: null
		};
	},
	computed: {
		filteredVisits() {
			return this.visits.filter((visit) => {
				return !this.currentStatus || this.currentStatus.includes(visit.statusid);
			});
		}
	},
	watch: {
		visitsLoaded(loaded) {
			this.$store.state.global.puiloader.show = !loaded;
		}
	},
	methods: {
		async fillSlotsFromResponse(response) {
			const today = this.$dateTimeUtils.getLocalDateNow();
			if (this.$dateTimeUtils.isLocalDateBefore(this.currentDate, today)) {
				this.slotsShipCount = null;
			} else {
				this.slotsShipCount = response.data;
			}
		},
		async fillVisitsFromResponse(response) {
			this.visitHour = {};
			this.visits = [];
			this.columns = [];
			this.dateTimeNow = new Date();
			this.currentDateLocal = this.getCurrentDateLocal(true);
			this.fillColumns();
			if (response.data && response.data.data && response.data.data.length > 0) {
				for (let i = 0, length = response.data.data.length; i < length; i++) {
					const visit = response.data.data[i];
					visit.notifications = this.subscriptions.includes(visit.id);
					// remove stops without ETA, ETD, ATA, ATD
					visit.stops = visit.stops.filter((stop) => {
						return stop.eta || stop.etd || stop.ata || stop.atd;
					});
					// sort stops by num
					visit.stops.sort((a, b) => (a.num > b.num ? 1 : b.num > a.num ? -1 : 0));

					if (visit.changes && visit.changes.eta && visit.changes.eta.value) {
						visit.eta = visit.changes.eta.value;
						visit.etaModified = true;
					}
					if (
						visit.stops.filter((stop) => {
							return stop.changes && stop.changes.eta && stop.changes.eta.value;
						}).length > 0
					) {
						visit.stopsEtaModified = true;
					}
					/*
					if (visit.stops.filter((stop) => { return stop.eta === visit.eta; }).length > 0) {
						visit.eta = this.$moment(visit.eta).subtract(2, 'hours').utc().format();
					}
					if (visit.stops.filter((stop) => { return stop.eta < visit.eta; }).length > 0) {
						const stop = visit.stops.filter((stop) => { return stop.eta < visit.eta; })[0];
						visit.eta = this.$moment(stop.eta).subtract(2, 'hours').utc().format();
					}
					if (visit.stops.filter((stop) => { return stop.etd === visit.etd; }).length > 0) {
						visit.etd = this.$moment(visit.etd).add(2, 'hours').utc().format();
					}
					*/
					this.convertDatesFromVisitStops(visit);
					this.overrideVisitATAWithAPSorPBA(visit);
					this.fillVisitHourFromVisitStops(visit);
					if (visit.withVisitHour) {
						await this.fillVisitPreviousPortAtd(visit);
						this.visits.push(visit);
					}
				}
			}
			this.visitsLoaded = true;
		},
		fillColumns() {
			const numColumns = this.zoomInToHoursPerColumn[this.zoomIn].numberOfColumns;
			const hoursPerColumn = this.zoomInToHoursPerColumn[this.zoomIn].hoursPerColumn;
			const hoursPerColumnMillis = hoursPerColumn * 60 * 60 * 1000;

			const firstDate = new Date(`${this.currentDate} 00:00`);
			firstDate.setDate(new Date(this.currentDate).getDate() - this.zoomIn);

			const firstDateColumn = this.getColumnDate(firstDate.getTime());
			let dd = firstDateColumn.split('/')[0];
			let MM = firstDateColumn.split('/')[1];

			let columnValue = 0;
			let columnLabel = hoursPerColumn === 1 ? '0' : `${dd}/${MM}`;
			let columnLabelExt = columnLabel;
			let columnMinDate = firstDate.getTime();
			let columnMaxDate = columnMinDate + hoursPerColumnMillis - 1000;
			this.columns = [
				{
					index: 0,
					hours: hoursPerColumn,
					value: columnValue,
					label: columnLabel,
					labelExt: columnLabelExt,
					minDateMillis: columnMinDate,
					maxDateMillis: columnMaxDate,
					minDate: this.getColumnDate(columnMinDate),
					maxDate: this.getColumnDate(columnMaxDate)
				}
			];

			for (let i = 1; i < numColumns; i++) {
				const newColumnDate = this.getColumnDate(this.columns[i - 1].maxDateMillis + 1000);
				dd = newColumnDate.split('/')[0];
				MM = newColumnDate.split('/')[1];
				columnValue = columnValue + hoursPerColumn < 24 ? columnValue + hoursPerColumn : 0;
				columnLabel = hoursPerColumn === 1 ? columnValue : columnValue !== 0 ? columnValue : `${dd}/${MM}`;
				columnLabelExt =
					columnLabel === 2
						? 'two'
						: columnLabel === 8
						? 'eight'
						: columnLabel === 14
						? 'fourteen'
						: columnLabel === 20
						? 'twenty'
						: columnLabel;
				columnMinDate = this.columns[i - 1].maxDateMillis + 1000;
				columnMaxDate = this.columns[i - 1].maxDateMillis + hoursPerColumnMillis;

				this.columns.push({
					index: i,
					hours: hoursPerColumn,
					value: columnValue,
					label: columnLabel,
					labelExt: columnLabelExt,
					minDateMillis: columnMinDate,
					maxDateMillis: columnMaxDate,
					minDate: this.getColumnDate(columnMinDate),
					maxDate: this.getColumnDate(columnMaxDate)
				});
			}
		},
		getColumnDate(dateMillis) {
			const columnDate = new Date(dateMillis);
			const dd = (columnDate.getDate() < 10 ? '0' : '') + columnDate.getDate();
			const MM = (columnDate.getMonth() + 1 < 10 ? '0' : '') + (columnDate.getMonth() + 1);
			const yyyy = columnDate.getFullYear();
			const hh = (columnDate.getHours() < 10 ? '0' : '') + columnDate.getHours();
			const mm = (columnDate.getMinutes() < 10 ? '0' : '') + columnDate.getMinutes();
			return `${dd}/${MM}/${yyyy} ${hh}:${mm}`;
		},
		overrideVisitATAWithAPSorPBA(visit) {
			if (visit.aps && (!visit.ata || this.isLocalDateSameOrBefore(visit.aps, visit.ata))) {
				visit.ata = visit.aps; // override ATA with APS
			}
			for (let i = 0, stopLength = visit.stops.length; i < stopLength; i++) {
				const stop = visit.stops[i];
				if (stop.pba && (!visit.ata || this.isLocalDateSameOrBefore(stop.pba, visit.ata))) {
					visit.ata = stop.pba; // override ATA with PBA
				}
			}
		},
		fillVisitHourFromVisitStops(visit) {
			for (let j = 0, columnsLength = this.columns.length; j < columnsLength; j++) {
				const column = this.columns[j];
				this.fillVisitStopHour(visit, column, 'eta', 'etd');
				this.fillVisitStopHour(visit, column, 'ata', 'atd');
				this.fillEventsHour(visit, column);
			}
			this.fillVisitPhase(visit);
			this.fillVisitStatus(visit);
			this.fillVesselStatus(visit);
		},
		fillVisitStopHour(visit, column, startProperty, endProperty) {
			const currentDateTimeStart = this.currentDateMin;
			const currentDateTimeEnd = this.currentDateMax;

			const stopResults = this.fillStopHour(visit, column, startProperty, endProperty, currentDateTimeStart, currentDateTimeEnd);
			if (stopResults) {
				visit.withVisitHour = true;
				this.visitHour[visit.id + '_' + column.index + startProperty + endProperty] = stopResults;
			}

			const visitResults = this.fillVisitHour(visit, column, startProperty, endProperty, currentDateTimeStart, currentDateTimeEnd);
			if (visitResults) {
				if (stopResults) {
					visitResults.first = stopResults.first || visitResults.first;
					visitResults.second = stopResults.second || visitResults.second;
					visitResults.third = stopResults.third || visitResults.third;
					visitResults.quarter = stopResults.quarter || visitResults.quarter;
					visitResults.fifth = stopResults.fifth || visitResults.fifth;
					visitResults.sixth = stopResults.sixth || visitResults.sixth;
					visitResults.seventh = stopResults.seventh || visitResults.seventh;
					visitResults.eighth = stopResults.eighth || visitResults.eighth;
				}
				visit.withVisitHour = true;
				this.visitHour[visit.id + '_' + column.index + startProperty + endProperty] = visitResults;
			}
		},
		fillVisitHour(visit, column, startProperty, endProperty, currentDateTimeStart, currentDateTimeEnd) {
			let result = null;

			const visitStart = visit[startProperty];
			const visitEnd = endProperty === 'etd' ? visit[endProperty] : visit[endProperty] || this.currentDateLocal;
			if (!visitStart) {
				return result;
			}

			const visitEtaBeforeCurrent = this.isLocalDateBefore(
				this.getOnlyDateFromDateTime(visitStart),
				this.getOnlyDateFromDateTime(currentDateTimeStart)
			);
			const visitEtdAfterCurrent =
				!visitEnd || this.isLocalDateAfter(this.getOnlyDateFromDateTime(visitEnd), this.getOnlyDateFromDateTime(currentDateTimeEnd));
			if (visitEtaBeforeCurrent) {
				// check visit ETD
				if (visitEtdAfterCurrent) {
					const obj = {
						type: this.getVisitType(visit, endProperty, column.minDate, column.maxDate),
						label: this.getVisitLabel(visit, startProperty, endProperty)
					};
					result = this.getResultByPosition(null, obj, obj, obj);
					if (endProperty === 'atd') {
						if (this.isCurrentDateBeforeColumn(column)) {
							result = null;
						}
					}
				} else {
					// visit ends today
					if (this.isLocalDateSameOrAfter(visitEnd, column.maxDate)) {
						const obj = {
							type: this.getVisitType(visit, endProperty, column.minDate, column.maxDate),
							label: this.getVisitLabel(visit, startProperty, endProperty)
						};
						result = this.getResultByPosition(null, obj, obj, obj);
					} else if (this.isLocalDateSameOrAfter(visitEnd, column.minDate)) {
						// visit ends today at this column
						const position = this.getPositionByDateColumn(visitEnd, column);
						const obj = {
							type: 'Departure',
							label: this.getVisitLabel(visit, startProperty, endProperty)
						};
						const objWithRightRadius = {
							type: 'Departure',
							label: this.getVisitLabel(visit, startProperty, endProperty),
							rightRadius: true
						};
						result = this.getResultByPosition(position, obj, objWithRightRadius, null);
					}
				}
			} else {
				const visitEtaAfterCurrent = this.isLocalDateAfter(
					this.getOnlyDateFromDateTime(visitStart),
					this.getOnlyDateFromDateTime(currentDateTimeEnd)
				);
				if (visitEtaAfterCurrent) {
					// visit starts and ends after current date
					return result;
				}

				// visit starts today
				if (this.isLocalDateSameOrAfter(visitStart, column.minDate) && this.isLocalDateSameOrBefore(visitStart, column.maxDate)) {
					// visit starts today at this column
					const position = this.getPositionByDateColumn(visitStart, column);
					if (visitEtdAfterCurrent) {
						const obj = {
							type: this.getVisitType(visit, endProperty, column.minDate, column.maxDate),
							label: this.getVisitLabel(visit, startProperty, endProperty)
						};
						const objWithLeftRadius = {
							type: this.getVisitType(visit, endProperty, column.minDate, column.maxDate),
							label: this.getVisitLabel(visit, startProperty, endProperty),
							leftRadius: true
						};
						result = this.getResultByPosition(position, null, objWithLeftRadius, obj);
						if (endProperty === 'atd') {
							if (this.isCurrentDateBeforeColumn(column)) {
								result = null;
							}
						}
					} else {
						// visit stops today
						if (this.isLocalDateSameOrAfter(visitEnd, column.maxDate)) {
							// visit starts in current hour and ends after current hour
							const obj = {
								type: this.getVisitType(visit, endProperty, column.minDate, column.maxDate),
								label: this.getVisitLabel(visit, startProperty, endProperty)
							};
							const objWithLeftRadius = {
								type: this.getVisitType(visit, endProperty, column.minDate, column.maxDate),
								label: this.getVisitLabel(visit, startProperty, endProperty),
								leftRadius: true
							};
							result = this.getResultByPosition(position, null, objWithLeftRadius, obj);
						} else {
							// visit starts and ends in current column...
							console.log('visit starts and ends in current column...', visitStart, visitEnd, result);
						}
					}
				} else if (this.isLocalDateSameOrBefore(visitStart, column.minDate)) {
					// check visit ETD
					if (visitEtdAfterCurrent) {
						const obj = {
							type: this.getVisitType(visit, endProperty, column.minDate, column.maxDate),
							label: this.getVisitLabel(visit, startProperty, endProperty)
						};
						result = this.getResultByPosition(null, obj, obj, obj);
						if (endProperty === 'atd') {
							if (this.isCurrentDateBeforeColumn(column)) {
								result = null;
							}
						}
					} else {
						// today
						if (this.isLocalDateSameOrAfter(visitEnd, column.maxDate)) {
							// visit starts before and ends after current column
							const obj = {
								type: this.getVisitType(visit, endProperty, column.minDate, column.maxDate),
								label: this.getVisitLabel(visit, startProperty, endProperty)
							};
							result = this.getResultByPosition(null, obj, obj, obj);
						} else if (this.isLocalDateSameOrAfter(visitEnd, column.minDate) && this.isLocalDateSameOrBefore(visitEnd, column.maxDate)) {
							// visit starts before and ends in current column
							const position = this.getPositionByDateColumn(visitEnd, column);
							const obj = {
								type: 'Departure',
								label: this.getVisitLabel(visit, startProperty, endProperty)
							};
							const objWithRightRadius = {
								type: 'Departure',
								label: this.getVisitLabel(visit, startProperty, endProperty),
								rightRadius: true
							};
							result = this.getResultByPosition(position, obj, objWithRightRadius, null);
						}
					}
				}
			}

			return result;
		},
		fillStopHour(visit, column, startProperty, endProperty, currentDateTimeStart, currentDateTimeEnd) {
			let result = null;

			for (let i = 0, stopLength = visit.stops.length; i < stopLength; i++) {
				const stop = visit.stops[i];

				const stopStart = stop[startProperty];
				const stopEnd = endProperty === 'etd' ? stop[endProperty] : stop[endProperty] || this.currentDateLocal;
				if (!stopStart) {
					continue;
				}

				const stopEtaBeforeCurrent = this.isLocalDateBefore(
					this.getOnlyDateFromDateTime(stopStart),
					this.getOnlyDateFromDateTime(currentDateTimeStart)
				);
				if (stopEtaBeforeCurrent) {
					// Check stop ETD
					const stopEtdBeforeCurrent =
						stopEnd && this.isLocalDateBefore(this.getOnlyDateFromDateTime(stopEnd), this.getOnlyDateFromDateTime(currentDateTimeStart));
					if (stopEtdBeforeCurrent) {
						continue;
					}

					const stopEtdAfterCurrent =
						!stopEnd || this.isLocalDateAfter(this.getOnlyDateFromDateTime(stopEnd), this.getOnlyDateFromDateTime(currentDateTimeEnd));
					if (stopEtdAfterCurrent) {
						// stop starts before and ends after current column
						const obj = {
							type: this.getStopType(stop),
							label: this.getStopLabel(stop, startProperty, endProperty)
						};
						result = this.getResultByPosition(null, obj, obj, obj);
						if (endProperty === 'atd') {
							if (this.isCurrentDateBeforeColumn(column)) {
								result = null;
							}
						}
						break;
					} else {
						// stop ends today
						if (this.isLocalDateSameOrAfter(stopEnd, column.maxDate)) {
							const obj = {
								type: this.getStopType(stop),
								label: this.getStopLabel(stop, startProperty, endProperty),
								rightRadius: false
							};
							result = this.getResultByPosition(null, obj, obj, obj);
							break;
						} else if (this.isLocalDateSameOrAfter(stopEnd, column.minDate) && this.isLocalDateSameOrBefore(stopEnd, column.maxDate)) {
							// stop ends today at this column
							const position = this.getPositionByDateColumn(stopEnd, column);
							const obj = {
								type: this.getStopType(stop),
								label: this.getStopLabel(stop, startProperty, endProperty)
							};
							const objWithRightRadius = {
								type: this.getStopType(stop),
								label: this.getStopLabel(stop, startProperty, endProperty),
								rightRadius: true
							};
							const resultTemp = this.getResultByPosition(position, obj, objWithRightRadius, null);
							result =
								result === null
									? resultTemp
									: {
											first: resultTemp.first || result.first,
											second: resultTemp.second || result.second,
											third: resultTemp.third || result.third,
											quarter: resultTemp.quarter || result.quarter,
											fifth: resultTemp.fifth || result.fifth,
											sixth: resultTemp.sixth || result.sixth,
											seventh: resultTemp.seventh || result.seventh,
											eighth: resultTemp.eighth || result.eighth
									  }; // eslint-disable-line
							if (position === 'eighth') {
								break;
							}
						}
					}
				} else {
					const stopEtaAfterCurrent = this.isLocalDateAfter(
						this.getOnlyDateFromDateTime(stopStart),
						this.getOnlyDateFromDateTime(currentDateTimeEnd)
					);
					if (stopEtaAfterCurrent) {
						// stop start and ends after current date
						continue;
					}

					if (this.isLocalDateSameOrAfter(stopStart, column.minDate) && this.isLocalDateSameOrBefore(stopStart, column.maxDate)) {
						// stop start at this column
						const etaPosition = this.getPositionByDateColumn(stopStart, column);
						const obj = {
							type: this.getStopType(stop),
							label: this.getStopLabel(stop, startProperty, endProperty)
						};
						const objWithLeftRadius = {
							type: this.getStopType(stop),
							label: this.getStopLabel(stop, startProperty, endProperty),
							leftRadius: true
						};
						const resultTemp = this.getResultByPosition(etaPosition, null, objWithLeftRadius, obj);
						result =
							result === null
								? resultTemp
								: {
										first: resultTemp.first || result.first,
										second: resultTemp.second || result.second,
										third: resultTemp.third || result.third,
										quarter: resultTemp.quarter || result.quarter,
										fifth: resultTemp.fifth || result.fifth,
										sixth: resultTemp.sixth || result.sixth,
										seventh: resultTemp.seventh || result.seventh,
										eighth: resultTemp.eighth || result.eighth
								  }; // eslint-disable-line

						if (
							stopEnd &&
							this.isLocalDateSameOrAfter(stopEnd, column.minDate) &&
							this.isLocalDateSameOrBefore(stopEnd, column.maxDate)
						) {
							const etdPosition = this.getPositionByDateColumn(stopEnd, column);
							const objWithRightRadius = {
								type: this.getStopType(stop),
								label: this.getStopLabel(stop, startProperty, endProperty),
								rightRadius: true
							};
							if (etdPosition === 'first') {
								result = {
									first: objWithRightRadius,
									second: null,
									third: null,
									quarter: null,
									fifth: null,
									sixth: null,
									seventh: null,
									eighth: null
								};
							} else if (etdPosition === 'second') {
								result = {
									first: result.first,
									second: objWithRightRadius,
									third: null,
									quarter: null,
									fifth: null,
									sixth: null,
									seventh: null,
									eighth: null
								};
							} else if (etdPosition === 'third') {
								result = {
									first: result.first,
									second: result.second,
									third: objWithRightRadius,
									quarter: null,
									fifth: null,
									sixth: null,
									seventh: null,
									eighth: null
								};
							} else if (etdPosition === 'quarter') {
								result = {
									first: result.first,
									second: result.second,
									third: result.third,
									quarter: objWithRightRadius,
									fifth: null,
									sixth: null,
									seventh: null,
									eighth: null
								};
							} else if (etdPosition === 'fifth') {
								result = {
									first: result.first,
									second: result.second,
									third: result.third,
									quarter: result.quarter,
									fifth: objWithRightRadius,
									sixth: null,
									seventh: null,
									eighth: null
								};
							} else if (etdPosition === 'sixth') {
								result = {
									first: result.first,
									second: result.second,
									third: result.third,
									quarter: result.quarter,
									fifth: result.fifth,
									sixth: objWithRightRadius,
									seventh: null,
									eighth: null
								};
							} else if (etdPosition === 'seventh') {
								result = {
									first: result.first,
									second: result.second,
									third: result.third,
									quarter: result.quarter,
									fifth: result.fifth,
									sixth: result.sixth,
									seventh: objWithRightRadius,
									eighth: null
								};
							} else {
								result = {
									first: result.first,
									second: result.second,
									third: result.third,
									quarter: result.quarter,
									fifth: result.fifth,
									sixth: result.sixth,
									seventh: result.seventh,
									eighth: objWithRightRadius
								};
								break;
							}
						}
					} else if (this.isLocalDateSameOrBefore(stopStart, column.minDate)) {
						// Check stop ETD
						const stopEtdAfterCurrent =
							!stopEnd ||
							this.isLocalDateAfter(this.getOnlyDateFromDateTime(stopEnd), this.getOnlyDateFromDateTime(currentDateTimeEnd));
						if (stopEtdAfterCurrent) {
							const obj = {
								type: this.getStopType(stop),
								label: this.getStopLabel(stop, startProperty, endProperty)
							};
							const resultTemp = this.getResultByPosition(null, obj, obj, obj);
							result =
								result === null
									? resultTemp
									: {
											first: resultTemp.first || result.first,
											second: resultTemp.second || result.second,
											third: resultTemp.third || result.third,
											quarter: resultTemp.quarter || result.quarter,
											fifth: resultTemp.fifth || result.fifth,
											sixth: resultTemp.sixth || result.sixth,
											seventh: resultTemp.seventh || result.seventh,
											eighth: resultTemp.eighth || result.eighth
									  }; // eslint-disable-line
							if (endProperty === 'atd') {
								if (this.isCurrentDateBeforeColumn(column)) {
									result = null;
								}
							}
							break;
						} else {
							// stop ends today
							if (this.isLocalDateSameOrAfter(stopEnd, column.maxDate)) {
								const obj = {
									type: this.getStopType(stop),
									label: this.getStopLabel(stop, startProperty, endProperty)
								};
								const resultTemp = this.getResultByPosition(null, obj, obj, obj);
								result =
									result === null
										? resultTemp
										: {
												first: resultTemp.first || result.first,
												second: resultTemp.second || result.second,
												third: resultTemp.third || result.third,
												quarter: resultTemp.quarter || result.quarter,
												fifth: resultTemp.fifth || result.fifth,
												sixth: resultTemp.sixth || result.sixth,
												seventh: resultTemp.seventh || result.seventh,
												eighth: resultTemp.eighth || result.eighth
										  }; // eslint-disable-line
							} else if (
								this.isLocalDateSameOrAfter(stopEnd, column.minDate) &&
								this.isLocalDateSameOrBefore(stopEnd, column.maxDate)
							) {
								// stop ends today at this column
								const position = this.getPositionByDateColumn(stopEnd, column);
								const obj = {
									type: this.getStopType(stop),
									label: this.getStopLabel(stop, startProperty, endProperty)
								};
								const objWithRightRadius = {
									type: this.getStopType(stop),
									label: this.getStopLabel(stop, startProperty, endProperty),
									rightRadius: true
								};
								const resultTemp = this.getResultByPosition(position, obj, objWithRightRadius, null);
								result =
									result === null
										? resultTemp
										: {
												first: resultTemp.first || result.first,
												second: resultTemp.second || result.second,
												third: resultTemp.third || result.third,
												quarter: resultTemp.quarter || result.quarter,
												fifth: resultTemp.fifth || result.fifth,
												sixth: resultTemp.sixth || result.sixth,
												seventh: resultTemp.seventh || result.seventh,
												eighth: resultTemp.eighth || result.eighth
										  }; // eslint-disable-line
								if (position === 'eighth') {
									break;
								}
							}
						}
					}
				}
			}

			return result;
		},
		fillEventsHour(visit, column) {
			let result = null;

			for (let i = 0, eventsLength = this.visitEvents.length; i < eventsLength; i++) {
				const event = this.visitEvents[i];
				const eventName = this.allEventsName[event];
				const eventDescription = this.allEventsDescription[event];
				const eventDate = visit[event];

				if (!eventDate) {
					continue;
				}

				const position = this.getPositionByDateColumn(eventDate, column);
				if (position) {
					result = result || {};
					result[position] = {
						position: position,
						event: eventName,
						label: this.getEventLabel(eventDescription, eventDate)
					};
				}
			}

			for (let i = 0, stopLength = visit.stops.length; i < stopLength; i++) {
				const stop = visit.stops[i];

				for (let j = 0, eventsLength = this.stopEvents.length; j < eventsLength; j++) {
					const event = this.stopEvents[j];
					const eventName = this.allEventsName[event];
					const eventDescription = this.allEventsDescription[event];
					const eventDate = stop[`${event}terminal`] || stop[event];

					if (!eventDate) {
						continue;
					}

					const position = this.getPositionByDateColumn(eventDate, column);

					if (position) {
						result = result || {};
						result[position] = {
							position: position,
							event: eventName,
							label: this.getEventLabel(eventDescription, eventDate)
						};
					}
				}
			}

			if (result) {
				this.visitHour[visit.id + '_' + column.index] = result;
			}
		},
		getPositionByDateColumn(date, column) {
			const dateSameOrAfter = this.isLocalDateSameOrAfter(date, column.minDate);
			const dateSameOrBefore = this.isLocalDateSameOrBefore(date, column.maxDate);
			if (!dateSameOrAfter || !dateSameOrBefore) {
				return null;
			}
			const millisPerBloc = this.zoomInToHoursPerColumn[this.zoomIn].millisPerBloc;
			if (!millisPerBloc) {
				console.error('OJO, no se ha definido la variable millisPerBloc para el nivel de zoom ' + this.zoomIn);
				return null;
			}

			let blocMillis = column.minDateMillis;

			const blocs = ['first', 'second', 'third', 'quarter', 'fifth', 'sixth', 'seventh', 'eighth'];
			for (let i = 0; i < blocs.length; i++) {
				const bloc = blocs[i];
				blocMillis += millisPerBloc;
				const blocDate = this.getColumnDate(blocMillis);
				if (this.isLocalDateSameOrBefore(date, blocDate)) {
					return bloc;
				}
			}

			return null;
		},
		getResultByPosition(position, beforePosition, inPosition, afterPosition) {
			let result = null;
			if (position === 'first') {
				result = {
					first: inPosition,
					second: afterPosition,
					third: afterPosition,
					quarter: afterPosition,
					fifth: afterPosition,
					sixth: afterPosition,
					seventh: afterPosition,
					eighth: afterPosition
				};
			} else if (position === 'second') {
				result = {
					first: beforePosition,
					second: inPosition,
					third: afterPosition,
					quarter: afterPosition,
					fifth: afterPosition,
					sixth: afterPosition,
					seventh: afterPosition,
					eighth: afterPosition
				};
			} else if (position === 'third') {
				result = {
					first: beforePosition,
					second: beforePosition,
					third: inPosition,
					quarter: afterPosition,
					fifth: afterPosition,
					sixth: afterPosition,
					seventh: afterPosition,
					eighth: afterPosition
				};
			} else if (position === 'quarter') {
				result = {
					first: beforePosition,
					second: beforePosition,
					third: beforePosition,
					quarter: inPosition,
					fifth: afterPosition,
					sixth: afterPosition,
					seventh: afterPosition,
					eighth: afterPosition
				};
			} else if (position === 'fifth') {
				result = {
					first: beforePosition,
					second: beforePosition,
					third: beforePosition,
					quarter: beforePosition,
					fifth: inPosition,
					sixth: afterPosition,
					seventh: afterPosition,
					eighth: afterPosition
				};
			} else if (position === 'sixth') {
				result = {
					first: beforePosition,
					second: beforePosition,
					third: beforePosition,
					quarter: beforePosition,
					fifth: beforePosition,
					sixth: inPosition,
					seventh: afterPosition,
					eighth: afterPosition
				};
			} else if (position === 'seventh') {
				result = {
					first: beforePosition,
					second: beforePosition,
					third: beforePosition,
					quarter: beforePosition,
					fifth: beforePosition,
					sixth: beforePosition,
					seventh: inPosition,
					eighth: afterPosition
				};
			} else {
				result = {
					first: beforePosition,
					second: beforePosition,
					third: beforePosition,
					quarter: beforePosition,
					fifth: beforePosition,
					sixth: beforePosition,
					seventh: beforePosition,
					eighth: inPosition
				};
			}
			return result;
		},
		getVisitType(visit, endProperty, columnStartDate, columnEndDate) {
			let type = 'Arrival';
			const lastStop = visit.stops[visit.stops.length - 1];

			if (
				lastStop &&
				lastStop[endProperty] &&
				(this.isLocalDateSameOrBefore(lastStop[endProperty], columnStartDate) ||
					this.isLocalDateSameOrBefore(lastStop[endProperty], columnEndDate))
			) {
				type = 'Departure';
			}
			return type;
		},
		getVisitLabel(visit, startProperty, endProperty) {
			let label = `${startProperty.toUpperCase()} PBP: ${visit[startProperty]}`;
			label += `, ${endProperty.toUpperCase()} PBP: ${
				visit[endProperty] !== null ? visit[endProperty] : this.$t('operationsdatatable.unknown')
			}`;
			return label;
		},
		getStopLabel(stop, startProperty, endProperty) {
			const type = stop.isanchorage ? this.$t('operationsdatatable.anchorage') : this.$t('operationsdatatable.berth');
			let label = `${stop.num} - ${stop.location !== null ? stop.location : stop.id}`;
			label += `, ${startProperty.toUpperCase()} ${type}: ${stop[startProperty]}`;
			if (startProperty === 'eta' && endProperty === 'etd' && stop.etc) {
				label += `, ETC: ${stop.etc}`;
			} else if (startProperty === 'ata' && endProperty === 'atd' && stop.atc) {
				label += `, ATC: ${stop.atc}`;
			}
			label += `, ${endProperty.toUpperCase()} ${type}:
				${stop[endProperty] !== null ? stop[endProperty] : this.$t('operationsdatatable.unknown')}`;
			return label;
		},
		getEventLabel(eventDescription, eventDate) {
			const [dateSplit, timeSplit] = eventDate.split(' ');
			const [day, month] = dateSplit.split('/');
			const newEventDate = `${day}/${month} ${timeSplit}`;
			return `${eventDescription}: ${newEventDate}`;
		},
		getStopType(stop) {
			return stop.isanchorage ? 'Anchored' : 'Berth';
		},
		isCurrentDateBeforeColumn(column) {
			if (this.dateTimeNow.getTime() <= column.minDateMillis) {
				return true;
			}
			return false;
		}
	}
};
</script>
