<template>
	<v-card>
		<v-card-text>
			<v-container class="pt-3 pr-0 pb-3 pl-0">
				<v-toolbar dense flat>
					<v-toolbar-title>{{ $t('operationsvisitdetailhistoryvisits.title') }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon ripple @click="closeDialog()">
						<v-icon>far fa-times</v-icon>
					</v-btn>
				</v-toolbar>
				<v-row dense v-if="visits.length > 0">
					<v-col cols="12">
						<operations-visit-detail-history-visits :visits="visits"></operations-visit-detail-history-visits>
					</v-col>
				</v-row>
				<v-row v-else>
					<v-col cols="12">{{ $t('operationsvisitdetailhistoryvisits.nopreviousdata') }}</v-col>
				</v-row>
				<v-toolbar dense flat>
					<v-toolbar-title>{{ $t('operationsvisitdetailhistoryvisits.evolution') }}</v-toolbar-title>
				</v-toolbar>
				<v-row v-if="chartData.length > 0">
					<v-col cols="12">
						<operations-bar-chart :chartData="chartData"></operations-bar-chart>
					</v-col>
				</v-row>
				<v-row v-else>
					<v-col cols="12">{{ $t('operationsvisitdetailhistoryvisits.nopreviousdata') }}</v-col>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>
</template>

<script>
import OperationsVisitDetailHistoryVisits from './OperationsVisitDetailHistoryVisits.vue';
import OperationsBarChart from '../OperationsBarChart.vue';
import DifferenceMixin from '@/mixins/HHmmDifferenceMixin';
import { color as am4core_color } from '@amcharts/amcharts4/core';

export default {
	components: { OperationsVisitDetailHistoryVisits, OperationsBarChart },
	mixins: [DifferenceMixin],
	props: {
		visit: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			visits: [],
			chartData: []
		};
	},
	async created() {
		this.$store.state.global.puiloader.show = true;
		this.visits = await this.getVisitsByVesselImo(this.visit.vesselimo);
		this.$store.state.global.puiloader.show = false;
	},
	methods: {
		async getVisitsByVesselImo(vesselimo) {
			const opts = {
				model: 'visit',
				page: 1,
				rows: 5,
				order: [{ column: 'eta', direction: 'desc' }],
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'vesselimo', op: 'eq', data: vesselimo },
						{ field: 'ata', op: 'nn', data: null },
						{ field: 'atd', op: 'nn', data: null }
					]
				}
			};
			const response = await this.$puiRequests.postRequest('/puisearch', opts);
			const visits = [];

			for (let i = 0, visitsLength = response.data.data.length; i < visitsLength; i++) {
				const visit = JSON.parse(JSON.stringify(response.data.data[i]));
				this.convertDatesFromVisit(visit);
				this.calculateDifferences(visit);
				visits.unshift(visit);
			}

			this.fillChartData(visits);

			return visits;
		},
		convertDatesFromVisit(visit) {
			if (visit.eta) {
				visit.etaComparable = visit.eta;
				visit.eta = this.$dateTimeUtils.getLocalFormattedDate(visit.eta, this.ddMMyyyHHmmFormat);
			}
			if (visit.etd) {
				visit.etdComparable = visit.etd;
				visit.etd = this.$dateTimeUtils.getLocalFormattedDate(visit.etd, this.ddMMyyyHHmmFormat);
			}
			if (visit.ata) {
				visit.ataComparable = visit.ata;
				visit.ata = this.$dateTimeUtils.getLocalFormattedDate(visit.ata, this.ddMMyyyHHmmFormat);
			}
			if (visit.atd) {
				visit.atdComparable = visit.atd;
				visit.atd = this.$dateTimeUtils.getLocalFormattedDate(visit.atd, this.ddMMyyyHHmmFormat);
			}
		},
		calculateDifferences(visit) {
			visit.diffEtaAta = this.getDifference(visit.ata, visit.eta);
			visit.diffEtaAtaFormatted = this.formatDifference(visit.diffEtaAta);

			visit.diffEtdAtd = this.getDifference(visit.atd, visit.etd);
			visit.diffEtdAtdFormatted = this.formatDifference(visit.diffEtdAtd);

			const etcSafetyMargin = this.$store.state.operations.etcSafetyMargin;

			if (etcSafetyMargin) {
				const diffEtaAtaMinutes = this.getMinutes(visit.diffEtaAta);
				visit.diffEtaAtaColor = 'green';
				if (diffEtaAtaMinutes > etcSafetyMargin) {
					visit.diffEtaAtaColor = 'red';
				}
				const diffEtdAtdMinutes = this.getMinutes(visit.diffEtdAtd);
				visit.diffEtdAtdColor = 'green';
				if (diffEtdAtdMinutes > etcSafetyMargin) {
					visit.diffEtdAtdColor = 'red';
				}
			}

			visit.diffAtaAtd = this.getDifference(visit.atd, visit.ata);
			visit.diffAtaAtdMinutes = this.getMinutes(visit.diffAtaAtd);
			visit.diffAtaAtdFormatted = this.formatDifference(visit.diffAtaAtd);
		},
		getMinutes(value) {
			if (value) {
				const [hours, minutes] = value.split(':');
				const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
				return totalMinutes;
			}
			return 0;
		},
		fillChartData(visits) {
			let visitWithMinusMinutes = null;
			let visitWithPlusMinutes = null;
			if (visits.length > 1) {
				const visitsSortByMinutes = visits.slice(0);
				visitsSortByMinutes.sort((a, b) =>
					a.diffAtaAtdMinutes > b.diffAtaAtdMinutes ? 1 : b.diffAtaAtdMinutes > a.diffAtaAtdMinutes ? -1 : 0
				);
				visitWithMinusMinutes = visitsSortByMinutes[0];
				visitWithPlusMinutes = visitsSortByMinutes[visitsSortByMinutes.length - 1];
			}

			for (let i = 0; i < visits.length; i++) {
				const visit = visits[i];
				let color = null;
				if (visitWithMinusMinutes && visitWithMinusMinutes.id === visit.id) {
					color = am4core_color('green');
				}
				if (visitWithPlusMinutes && visitWithPlusMinutes.id === visit.id) {
					color = am4core_color('red');
				}
				this.chartData.push({
					id: visit.ata.split(' ')[0],
					name: visit.portcallnumber,
					time: visit.diffAtaAtdFormatted,
					minutes: visit.diffAtaAtdMinutes,
					color: color
				});
			}
		},
		closeDialog() {
			this.$emit('close-dialog');
		}
	}
};
</script>
