export default {
	data() {
		return {
			ddMMyyyHHmmFormat: 'DD/MM/YYYY HH:mm'
		};
	},
	methods: {
		getDifference(start, end) {
			if (!start || !end) {
				return '';
			}
			let ms = this.$moment(end, this.ddMMyyyHHmmFormat).diff(this.$moment(start, this.ddMMyyyHHmmFormat));
			if (ms < 0) {
				ms = this.$moment(start, this.ddMMyyyHHmmFormat).diff(this.$moment(end, this.ddMMyyyHHmmFormat));
			}
			const days = this.$moment.duration(ms);
			return Math.floor(days.asHours()) + this.$moment.utc(ms).format(':mm');
		},
		formatDifference(hoursMinutes) {
			if (hoursMinutes) {
				return hoursMinutes.replace(':', 'h') + "'";
			}
			return hoursMinutes;
		},
		getAMPM(date) {
			try {
				return (date.split(' ')[1].substring(0, 2) < 12) ? ' am' : ' pm';
			} catch (e) {}
			return '';
		}
	}
};
