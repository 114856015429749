<template>
	<v-container class="operations-visit-detail-notfinalized">
		<v-layout wrap text-center>
			<v-flex xs12 md4>
				<h1 :class="plannedDifferenceCompClass">{{ plannedDifference }}</h1>
				<span>{{ $t('operationsvisitdetailstopnotfinalized.plannedDifference') }}</span>
			</v-flex>
			<v-flex xs12 md4>
				<h1>{{ runningTime }}</h1>
				<span>{{ $t('operationsvisitdetailstopnotfinalized.runningTime') }}</span>
			</v-flex>
			<v-flex xs12 md4>
				<h1>{{ stayProgress }}%</h1>
				<span>{{ $t('operationsvisitdetailstopnotfinalized.stayProgress') }}</span>
			</v-flex>
		</v-layout>
		<v-layout wrap>
			<v-progress-linear color="success" height="20" :value="stayProgress"></v-progress-linear>
		</v-layout>
	</v-container>
</template>

<script>
import DateTimeMixin from '@/mixins/DateTimeMixin';
import HHmmDifferenceMixin from '@/mixins/HHmmDifferenceMixin';
import EventsMixin from '@/mixins/EventsMixin';

export default {
	mixins: [DateTimeMixin, HHmmDifferenceMixin, EventsMixin],
	props: {
		visit: {
			type: Object,
			required: true
		},
		stopSelected: {
			type: Object,
			required: true
		}
	},
	computed: {
		plannedDifferenceCompClass() {
			if (!this.stopSelected.eta || !this.stopSelected.ata) {
				return '';
			}

			const etaFormatted = this.getYearMonthDay(this.stopSelected.eta, true);
			const ataFormatted = this.getYearMonthDay(this.stopSelected.ata, true);

			if (this.$dateTimeUtils.isLocalDateAfter(etaFormatted, ataFormatted)) {
				return 'planned-difference__green';
			} else if (this.$dateTimeUtils.isLocalDateBefore(etaFormatted, ataFormatted)) {
				return 'planned-difference__red';
			}
			return '';
		},
		plannedDifference() {
			if (!this.stopSelected.eta || !this.stopSelected.ata) {
				return '';
			}

			const diff = this.getDifference(this.stopSelected.eta, this.stopSelected.ata);
			return this.formatDifference(diff);
		},
		runningTime() {
			if (!this.stopSelected.ata) {
				return "0'";
			}

			const diff = this.getDifference(this.getCurrentDateLocal(true), this.stopSelected.ata);
			return this.formatDifference(diff);
		},
		stayProgress() {
			const stopType = this.stopSelected.isanchorage ? 'ANCHORED' : 'BERTH';
			let eventsReceived = 0;

			for (let j = 0, stopEvents = this.stopEvents.length; j < stopEvents; j++) {
				const eventName = this.stopEvents[j];
				const eventType = this.stopEventsType[eventName];

				if (eventType === 'ALL' || eventType === stopType) {
					const eventDate = this.stopSelected[eventName];
					if (eventDate) {
						eventsReceived += 1;
					}
				}
			}

			return stopType === 'BERTH' ? (eventsReceived / 8) * 100 : (eventsReceived / 4) * 100;
		}
	}
};
</script>

<style lang="postcss" scoped>
.operations-visit-detail-notfinalized {
	padding: 48px 2px;
}
.planned-difference__red {
	color: red;
}
.planned-difference__green {
	color: green;
}
</style>
