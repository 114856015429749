<template>
	<v-menu v-model="menu" :close-on-content-click="false" offset-y>
		<template v-slot:activator="{ on: menuActivator }">
			<v-tooltip top :z-index="200">
				<template v-slot:activator="{ on: tooltip }">
					<v-icon v-on="{ ...tooltip, ...menuActivator }" small>fas fa-calculator</v-icon>
				</template>
				<span>{{ $t('operationsvisitprediction.tooltip') }}</span>
			</v-tooltip>
		</template>

		<v-card class="operations-visit-prediction">
			<v-card-title>{{ $t('operationsvisitprediction.title') }}</v-card-title>

			<v-card-text>
				<v-row dense>
					<v-col cols="12">
						<span class="prediction__title">{{ $t('operationsvisitprediction.at') + ' ' + $t('operationsvisitprediction.pbp') }}</span>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<span style="font-weight: 500">{{ $t('operationsvisitprediction.etaPredicted') }}</span>
					</v-col>
					<v-col cols="6">
						<span>{{ etaPredicted }}</span>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<span style="font-weight: 500">{{ $t('operationsvisitprediction.eta') }}</span>
					</v-col>
					<v-col cols="6">
						<span>{{ eta }}</span>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="1"><v-icon color="red">fas fa-exclamation-triangle</v-icon></v-col>
					<v-col cols="11">
						<span>{{ etaStatus }}</span>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12">
						<span class="prediction__title">{{ $t('operationsvisitprediction.at') + ' ' + $t('operationsvisitprediction.berth') }}</span>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<span style="font-weight: 500">{{ $t('operationsvisitprediction.etaPredicted') }}</span>
					</v-col>
					<v-col cols="6">
						<span>{{ berthEtaPredicted }}</span>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<span style="font-weight: 500">{{ $t('operationsvisitprediction.eta') }}</span>
					</v-col>
					<v-col cols="6">
						<span>{{ berthEta }}</span>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="1"><v-icon color="red">fas fa-exclamation-triangle</v-icon></v-col>
					<v-col cols="11">
						<span>{{ berthEtaStatus }}</span>
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn text @click="close()">{{ $t('pui9.accept') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
import MapService from '@/services/map.service';
import ScraperService from '@/services/scraper.service.js';

/** NOT USED */
export default {
	props: {
		visit: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			menu: false,
			localFormat: 'DD/MM/YYYY HH:mm',
			eta: this.visit.eta,
			etaPredicted: null,
			etaStatus: null,
			berthEta: this.visit.stops && this.visit.stops[0].eta,
			berthEtaPredicted: null,
			berthEtaStatus: null
		};
	},
	watch: {
		async menu(opened) {
			if (opened) {
				//let vesselInfo = await MapService.getVesselInfo(this.visit.vesselimo);
				let vesselInfo = await ScraperService.getVesselInfo(this.visit);

				if (!vesselInfo || !vesselInfo.longitude || !vesselInfo.latitude) {
					const statusMessage = this.$t('operationsvisitprediction.aisprovider.vesselnotfound');
					console.log(statusMessage, vesselInfo);
					this.etaStatus = statusMessage;
					this.berthEtaStatus = statusMessage;
					return;
				}

				const fromLonLat = `${vesselInfo.longitude},${vesselInfo.latitude}`;
				const toLonLat = '-5.4476898,36.12978'; // FIXME: Hardcoded Algeciras Port position
				let distance;
				// FIXME: filter service by user for not consuming all API credits!!!
				if (this.$store.getters.getUser !== 'amartinez') {
					distance = await MapService.getDistance(fromLonLat, toLonLat);
				}
				if (!distance) {
					const statusMessage = this.$t('operationsvisitprediction.aisprovider.distancenotfound');
					console.log(statusMessage, vesselInfo);
					this.etaStatus = statusMessage;
					this.berthEtaStatus = statusMessage;
					return;
				}

				if (!vesselInfo.speed || vesselInfo.speed < 0.9) {
					const statusMessage = `${this.$t('operationsvisitprediction.aisprovider.vesselstop')}
						${distance} ${this.$t('operationsvisitprediction.distance.miles')}`;
					console.log(statusMessage, vesselInfo);
					this.etaStatus = statusMessage;
					this.berthEtaStatus = statusMessage;
					return;
				}

				const speedInMetersPerSecond = vesselInfo.speed * 0.514444;
				const timeInSeconds = distance / speedInMetersPerSecond;
				const timeInMinutes = timeInSeconds * 0.0166667;

				if (!timeInMinutes) {
					const statusMessage = this.$t('operationsvisitprediction.etaPredictedUnknown');
					console.log(statusMessage, vesselInfo, distance);
					this.etaStatus = statusMessage;
					this.berthEtaStatus = statusMessage;
					return;
				}

				this.etaPredicted = this.$moment().add(timeInMinutes, 'minutes').format(this.localFormat);
				this.etaStatus = this.getEtaStatus(this.eta, this.etaPredicted, this.$t('operationsvisitprediction.pbp'));

				if (!this.visit.stops) {
					return;
				}

				const stop = this.visit.stops[0];
				//this.berthEta = stop.eta;
				const berthTimeInMinutes = this.getBerthTimeInMinutesDependingOnVesselLength(this.visit.vessellength);
				if (berthTimeInMinutes) {
					this.berthEtaPredicted = this.$moment()
						.add(timeInMinutes + berthTimeInMinutes, 'minutes')
						.format(this.localFormat);
					this.berthEtaStatus = this.getEtaStatus(stop.eta, this.berthEtaPredicted, this.$t('operationsvisitprediction.berth'));
				}
			}
		}
	},
	methods: {
		getEtaStatus(eta, etaPredicted, place) {
			let ms = this.$moment(eta, this.localFormat).diff(this.$moment(etaPredicted, this.localFormat));
			let etaStatusLabel = this.$t('operationsvisitprediction.etaAdvanced');
			if (ms < 0) {
				ms = this.$moment(etaPredicted, this.localFormat).diff(this.$moment(eta, this.localFormat));
				etaStatusLabel = this.$t('operationsvisitprediction.etaDelayed');
			}

			const days = this.$moment.duration(ms);
			const hours = Math.floor(days.asHours());

			if (hours >= 0) {
				const hoursMinutes = hours + this.$moment.utc(ms).format(':mm');
				const hoursMinutesFormatted = hoursMinutes.replace(':', 'h') + "'";
				return `${hoursMinutesFormatted} ${etaStatusLabel} ${place}`;
			}
			return '';
		},
		getBerthTimeInMinutesDependingOnVesselLength(visit) {
			if (visit.vessellength < 200) {
				return 30.43;
			} else if (visit.vessellength >= 200 && visit.vessellength < 290) {
				return 33.03;
			}
			return 36.61;
		},
		close() {
			this.menu = false;
		}
	}
};
</script>

<style lang="postcss">
.operations-visit-prediction {
	& span {
		&.prediction__title {
			font-weight: 600;
			font-size: 15px;
		}
	}
	& .v-btn {
		min-width: 50px;
	}
}
</style>
