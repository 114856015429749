import * as am4core from '@amcharts/amcharts4/core';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import OperationsVisitsMixin from '@/mixins/OperationsVisitsMixin';

export default {
	mixins: [DateTimeMixin, OperationsVisitsMixin],
	data() {
		return {
			chartDataLength: 0
		};
	},
	methods: {
		getChartDataFromVisits(visits, minDate, maxDate) {
			const chartData = [];
			this.chartDataLength = 0;

			for (let i = 0, length = visits.length; i < length; i++) {
				const visit = visits[i];
				this.convertDatesFromVisitStops(visit);
				this.fillVisitPhase(visit);
				this.fillVisitStatus(visit);
				const estimatedChartDataItems = this.getChartDataFromVisit(visit, 'eta', 'etd', minDate, maxDate);
				Array.prototype.push.apply(chartData, estimatedChartDataItems);
				const realChartDataItems = this.getChartDataFromVisit(visit, 'ata', 'atd', minDate, maxDate);
				Array.prototype.push.apply(chartData, realChartDataItems);
				const eventsChartDataItems = this.getChartDataEventsFromVisit(visit, minDate, maxDate);
				Array.prototype.push.apply(chartData, eventsChartDataItems);

				if (estimatedChartDataItems.length > 0 || realChartDataItems.length > 0) {
					this.chartDataLength += 1;
				}
			}
			return chartData;
		},
		getChartDataFromVisit(visit, startProperty, endProperty, minDate, maxDate) {
			const chartDataItems = [];

			if (!visit[startProperty] && !visit[endProperty]) {
				return chartDataItems;
			}
			if (visit[startProperty] && this.isLocalDateSameOrAfter(visit[startProperty], maxDate)) {
				return chartDataItems;
			}
			if (visit[endProperty] && this.isLocalDateSameOrBefore(visit[endProperty], minDate)) {
				return chartDataItems;
			}

			const visitChartDataItem = this.getVisitChartDataItem(visit, startProperty, endProperty);

			if (!visit[endProperty] && endProperty === 'atd' && visitChartDataItem.realFromDate) {
				visitChartDataItem.realToDate = this.toChartDate(this.getCurrentDateLocal(true));
			}

			chartDataItems.push(visitChartDataItem);

			for (let i = 0, length = visit.stops.length; i < length; i++) {
				const stop = visit.stops[i];

				if (!stop[startProperty] && !stop[endProperty]) {
					return chartDataItems;
				}
				if (stop[startProperty] && this.isLocalDateSameOrAfter(stop[startProperty], maxDate)) {
					continue;
				}
				if (stop[endProperty] && this.isLocalDateSameOrBefore(stop[endProperty], minDate)) {
					continue;
				}

				const stopChartDataItem = this.getStopChartDataItem(visit, stop, startProperty, endProperty);

				if (!stop[endProperty] && endProperty === 'atd' && stopChartDataItem.realFromDate) {
					stopChartDataItem.realToDate = this.toChartDate(this.getCurrentDateLocal(true));
				}

				chartDataItems.push(stopChartDataItem);
			}

			return chartDataItems;
		},
		getVisitChartDataItem(visit, startProperty, endProperty) {
			const chartDataItem = {
				id: visit.id,
				portcallnumber: visit.portcallnumber,
				vesselname: visit.vesselname,
				phase: visit.phase,
				statusname: visit.statusname,
				statusdesc: visit.statusdesc,
				location: '',
				date: null,
				isVisit: true
			};

			chartDataItem[startProperty] = visit[startProperty];
			chartDataItem[endProperty] = visit[endProperty];
			if (startProperty === 'eta' && endProperty === 'etd') {
				chartDataItem.fromDate = this.toChartDate(visit[startProperty]);
				chartDataItem.toDate = this.toChartDate(visit[endProperty]);
				chartDataItem.color = am4core.color('#F1BCF2');
			} else {
				chartDataItem.realFromDate = this.toChartDate(visit[startProperty]);
				chartDataItem.realToDate = this.toChartDate(visit[endProperty]);
				chartDataItem.color = am4core.color('#B758D9');
			}

			return chartDataItem;
		},
		getStopChartDataItem(visit, stop, startProperty, endProperty) {
			const chartDataItem = {
				id: stop.id,
				portcallnumber: visit.portcallnumber,
				vesselname: visit.vesselname,
				phase: visit.phase,
				statusname: visit.statusname,
				statusdesc: visit.statusdesc,
				location: stop.location,
				date: null
			};

			chartDataItem[startProperty] = stop[startProperty];
			chartDataItem[endProperty] = stop[endProperty];
			chartDataItem.etc = stop.etc;
			if (startProperty === 'eta' && endProperty === 'etd') {
				chartDataItem.fromDate = this.toChartDate(stop[startProperty]);
				chartDataItem.toDate = this.toChartDate(stop[endProperty]);
				chartDataItem.color = am4core.color(!stop.isanchorage ? '#BADD8E' : '#AFDEF8');
			} else {
				chartDataItem.realFromDate = this.toChartDate(stop[startProperty]);
				chartDataItem.realToDate = this.toChartDate(stop[endProperty]);
				chartDataItem.color = am4core.color(!stop.isanchorage ? '#4FA439' : '#1F98EF');
			}

			return chartDataItem;
		},
		toChartDate(date) {
			if (date) {
				const jsDate = new Date(this.getYearMonthDay(date, true));
				return jsDate.getTime();
			}
			return null;
		},
		getChartDataEventsFromVisit(visit, minDate, maxDate) {
			const chartDataItems = [];

			for (let i = 0, eventsLength = this.visitEvents.length; i < eventsLength; i++) {
				const event = this.visitEvents[i];
				const eventName = this.allEventsName[event];
				const eventDesc = this.allEventsDescription[event];
				const eventDate = visit[event];

				if (!eventDate) {
					continue;
				} else if (minDate && maxDate && (this.isLocalDateBefore(eventDate, minDate) || this.isLocalDateAfter(eventDate, maxDate))) {
					continue;
				}

				chartDataItems.push(this.getEventChartDataItem(visit, eventName, eventDesc, eventDate));
			}

			for (let i = 0, stopLength = visit.stops.length; i < stopLength; i++) {
				const stop = visit.stops[i];

				for (let j = 0, stopEvents = this.stopEvents.length; j < stopEvents; j++) {
					const event = this.stopEvents[j];
					const eventName = this.allEventsName[event];
					const eventDesc = this.allEventsDescription[event];
					const eventDate = stop[event];

					if (!eventDate) {
						continue;
					} else if (minDate && maxDate && (this.isLocalDateBefore(eventDate, minDate) || this.isLocalDateAfter(eventDate, maxDate))) {
						continue;
					}

					chartDataItems.push(this.getEventChartDataItem(visit, eventName, eventDesc, eventDate));
				}
			}

			return chartDataItems;
		},
		getEventChartDataItem(visit, eventName, eventDesc, eventDate) {
			const chartDataItem = {
				portcallnumber: visit.portcallnumber,
				vesselname: visit.vesselname,
				eventName: eventName,
				eventDesc: eventDesc,
				eventDate: eventDate,
				date: this.toChartDate(eventDate)
			};
			return chartDataItem;
		}
	}
};
