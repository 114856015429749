<template>
	<table class="operations-visit-detail-table">
		<tr class="operations-visit-detail-table__header">
			<th>{{ $t('operationsvisitdetaillogsevents.name') }}</th>
			<th>{{ $t('operationsvisitdetaillogsevents.value') }}</th>
			<th>{{ $t('operationsvisitdetaillogsevents.origin') }}</th>
			<th>{{ $t('operationsvisitdetaillogsevents.received') }}</th>
		</tr>
		<tr class="operations-visit-detail-table__content" v-for="(event, index) in events" :key="index">
			<td>
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<span v-on="on">{{ event.name }}</span>
					</template>
					<span>{{ event.description }}</span>
				</v-tooltip>
			</td>
			<td>{{ event.value }}</td>
			<td>{{ event.origin }}</td>
			<td>
				<span>{{ event.received }}</span>
				<span class="operations-visit-detail-table__content--receivedDiff">{{ event.receivedDiff }}</span>
			</td>
		</tr>
	</table>
</template>

<script>
export default {
	props: {
		events: {
			type: Array,
			required: true
		}
	}
};
</script>

<style lang="postcss">
.operations-visit-detail-table__content--receivedDiff {
	font-size: 12px;
}
</style>
