<template>
	<v-container class="pa-3">
		<v-row class="pt-3">
			<v-col cols="12">
				<operations-visit-detail-logs-events :events="events"></operations-visit-detail-logs-events>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import OperationsVisitDetailLogsEvents from './OperationsVisitDetailLogsEvents';
import DateTimeMixin from '@/mixins/DateTimeMixin';

export default {
	components: { OperationsVisitDetailLogsEvents },
	mixins: [DateTimeMixin],
	props: {
		visit: {
			type: Object,
			required: true
		},
		stopSelected: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			events: []
		};
	},
	watch: {
		stopSelected() {
			this.fillEvents(this.stopSelected);
		}
	},
	mounted() {
		this.fillEvents(this.stopSelected);
	},
	methods: {
		fillEvents(stop) {
			this.events = [];

			const currentDateLocal = this.getCurrentDateLocal(true);

			if (this.visit.stops.length === 1 || (this.visit.stops.length > 1 && stop.num === 1)) {
				const event = 'aps';
				const eventName = this.allEventsName[event];
				const eventDescription = this.allEventsDescriptionLogs[event];
				const eventOrigin = this.eventOrigin[event];
				const eventDate = this.visit[event];
				const eventReceived = this.visit.apspsop;

				this.events.push({
					name: eventName,
					description: eventDescription,
					origin: eventOrigin,
					value: this.formatEventDate(eventDate),
					received: this.formatEventDate(eventReceived),
					receivedDiff: this.getReceivedDifference(eventReceived, currentDateLocal)
				});
			}

			for (let j = 0, stopEvents = this.stopEvents.length; j < stopEvents; j++) {
				const event = this.stopEvents[j];
				const eventName = this.allEventsName[event];
				const eventType = this.stopEventsType[event];
				const stopType = stop.isanchorage ? 'ANCHORED' : 'BERTH';

				if (eventType === 'ALL' || eventType === stopType) {
					const eventDescription = this.allEventsDescriptionLogs[event];
					const eventOrigin = this.eventOrigin[event];
					const eventDate = stop[event];
					const eventReceived = stop[`${event}psop`];

					this.events.push({
						name: eventName,
						description: eventDescription,
						origin: eventOrigin,
						value: this.formatEventDate(eventDate),
						received: this.formatEventDate(eventReceived),
						receivedDiff: this.getReceivedDifference(eventReceived, currentDateLocal)
					});
				}
			}

			if (this.visit.stops.length === 1 || (this.visit.stops.length > 1 && stop.num === this.visit.stops.length)) {
				const event = 'pof';
				const eventName = this.allEventsName[event];
				const eventDescription = this.allEventsDescriptionLogs[event];
				const eventOrigin = this.eventOrigin[event];
				const eventDate = this.visit[event];
				const eventReceived = this.visit.pofpsop;

				this.events.push({
					name: eventName,
					description: eventDescription,
					origin: eventOrigin,
					value: this.formatEventDate(eventDate),
					received: this.formatEventDate(eventReceived),
					receivedDiff: this.getReceivedDifference(eventReceived, currentDateLocal)
				});
			}
		},
		formatEventDate(eventDate) {
			if (eventDate) {
				const [dateSplit, timeSplit] = eventDate.split(' ');
				const [day, month] = dateSplit.split('/');
				return `${day}/${month} ${timeSplit}`;
			}
			return eventDate;
		},
		getReceivedDifference(eventDate, currentDate) {
			if (eventDate) {
				const diff = this.getDifference(currentDate, eventDate);
				if (diff) {
					let [hours, minutes] = diff.split(':');
					if (minutes > 0) {
						hours++;
					}
					let receivedDiff = null;
					if (hours <= 24) {
						receivedDiff = this.formatDifference(diff);
					} else if (hours > 24 && hours <= 48) {
						receivedDiff = this.$t('operationsvisitdetaillogs.1dayago');
					} else if (hours > 48 && hours <= 72) {
						receivedDiff = this.$t('operationsvisitdetaillogs.2daysago');
					} else if (hours > 72 && hours <= 168) {
						receivedDiff = this.$t('operationsvisitdetaillogs.morethan3daysago');
					} else {
						receivedDiff = this.$t('operationsvisitdetaillogs.morethan1week');
					}
					return ` (${receivedDiff}) `;
				}
			}
			return eventDate;
		}
	}
};
</script>
