<template>
	<table class="operations-visit-detail-table">
		<tr class="operations-visit-detail-table__header">
			<th>{{ $t('operationsvisitdetailperformancemetrics.name') }}</th>
			<th>{{ $t('operationsvisitdetailperformancemetrics.value') }}</th>
		</tr>
		<tr class="operations-visit-detail-table__content" v-for="(metric, index) in metrics" :key="index">
			<td>
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<span v-on="on">{{ metric.name }}</span>
					</template>
					<span>{{ metric.description }}</span>
				</v-tooltip>
			</td>
			<td>{{ metric.value }}</td>
		</tr>
	</table>
</template>

<script>
export default {
	props: {
		metrics: {
			type: Array,
			required: true
		}
	}
};
</script>
