import EventsMixin from '@/mixins/EventsMixin';
import DifferenceMixin from '@/mixins/HHmmDifferenceMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';

import MapService from '@/services/map.service';
import ScraperService from '@/services/scraper.service.js';

export default {
	mixins: [EventsMixin, DifferenceMixin, DateTimeMixin],
	methods: {
		convertDatesFromVisitStops(visit) {
			if (visit.eta) {
				visit.eta = this.$dateTimeUtils.getLocalFormattedDate(visit.eta, this.ddMMyyyHHmmFormat);
			}
			if (visit.etd) {
				visit.etd = this.$dateTimeUtils.getLocalFormattedDate(visit.etd, this.ddMMyyyHHmmFormat);
			}
			if (visit.ata) {
				visit.ata = this.$dateTimeUtils.getLocalFormattedDate(visit.ata, this.ddMMyyyHHmmFormat);
			}
			if (visit.atd) {
				visit.atd = this.$dateTimeUtils.getLocalFormattedDate(visit.atd, this.ddMMyyyHHmmFormat);
			}
			if (visit.atafromport) {
				visit.atafromportUtc = visit.atafromport;
				visit.atafromport = this.$dateTimeUtils.getLocalFormattedDate(visit.atafromport, this.ddMMyyyHHmmFormat);
			}
			if (visit.atdfromport) {
				visit.atdfromportUtc = visit.atdfromport;
				visit.atdfromport = this.$dateTimeUtils.getLocalFormattedDate(visit.atdfromport, this.ddMMyyyHHmmFormat);
			}

			for (let i = 0, eventsLength = this.visitEvents.length; i < eventsLength; i++) {
				const eventName = this.visitEvents[i];
				const eventDate = visit[eventName];
				if (!eventDate) {
					continue;
				}
				visit[eventName] = this.$dateTimeUtils.getLocalFormattedDate(eventDate, this.ddMMyyyHHmmFormat);
			}

			for (let i = 0, eventsLength = this.visitEventsPsop.length; i < eventsLength; i++) {
				const eventName = this.visitEventsPsop[i];
				const eventDate = visit[eventName];
				if (!eventDate) {
					continue;
				}
				visit[eventName] = this.$dateTimeUtils.getLocalFormattedDate(eventDate, this.ddMMyyyHHmmFormat);
			}

			for (let i = 0, stopLength = visit.stops.length; i < stopLength; i++) {
				const stop = visit.stops[i];

				if (stop.eta) {
					stop.eta = this.$dateTimeUtils.getLocalFormattedDate(stop.eta, this.ddMMyyyHHmmFormat);
				}
				if (stop.etd) {
					stop.etd = this.$dateTimeUtils.getLocalFormattedDate(stop.etd, this.ddMMyyyHHmmFormat);
				}
				if (stop.etc) {
					stop.etc = this.$dateTimeUtils.getLocalFormattedDate(stop.etc, this.ddMMyyyHHmmFormat);
				}
				if (stop.ata) {
					stop.ata = this.$dateTimeUtils.getLocalFormattedDate(stop.ata, this.ddMMyyyHHmmFormat);
				}
				if (stop.atd) {
					stop.atd = this.$dateTimeUtils.getLocalFormattedDate(stop.atd, this.ddMMyyyHHmmFormat);
				}
				if (stop.atc) {
					stop.atc = this.$dateTimeUtils.getLocalFormattedDate(stop.atc, this.ddMMyyyHHmmFormat);
				}

				for (let j = 0, stopEvents = this.stopEvents.length; j < stopEvents; j++) {
					const eventName = this.stopEvents[j];
					const eventDate = stop[eventName];

					if (!eventDate) {
						continue;
					}
					stop[eventName] = this.$dateTimeUtils.getLocalFormattedDate(eventDate, this.ddMMyyyHHmmFormat);
				}

				for (let j = 0, stopEvents = this.stopEventsPsop.length; j < stopEvents; j++) {
					const eventName = this.stopEventsPsop[j];
					const eventDate = stop[eventName];

					if (!eventDate) {
						continue;
					}
					stop[eventName] = this.$dateTimeUtils.getLocalFormattedDate(eventDate, this.ddMMyyyHHmmFormat);
				}

				for (let j = 0, stopEvents = this.stopEventsTerminal.length; j < stopEvents; j++) {
					const eventName = this.stopEventsTerminal[j];
					const eventDate = stop[eventName];

					if (!eventDate) {
						continue;
					}
					stop[eventName] = this.$dateTimeUtils.getLocalFormattedDate(eventDate, this.ddMMyyyHHmmFormat);
				}
			}
		},
		fillVisitPhase(visit) {
			if (visit.statusid === 'AN') {
				// visit cancelled - do nothing
			} else if (visit.atd) {
				// visit finalized
				const stops = visit.stops.filter((stop) => !stop.isanchorage && stop.departureidletime);
				if (stops && stops.length === 1) {
					const departureidletime = stops[0].departureidletime;

					try {
						visit.departureidletime = `${this.$t('operationsdatatable.phase.departureidletime')}: ${departureidletime}`;
						const hoursMinutes = departureidletime.split(':');
						visit.departureidletimegreaterthan45mins = parseInt(hoursMinutes[0]) > 0 || parseInt(hoursMinutes[1]) > 45;
					} catch (e) {}
				} else {
					let actualdeparture = visit.atd;
					try {
						actualdeparture += visit.atd.split(' ')[1].substring(0, 2) < 12 ? ' am' : ' pm';
					} catch (e) {}

					visit.phase = `${this.$t('operationsdatatable.phase.actualdeparture.title')} ${actualdeparture}`;
					const diff = this.$moment(visit.atd, this.ddMMyyyHHmmFormat).diff(this.$moment(visit.etd, this.ddMMyyyHHmmFormat));
					const delayoradvance = this.formatDifference(this.getDifference(visit.atd, visit.etd));

					if (diff > 0) {
						visit.phasedesc = `${this.$t('operationsdatatable.phase.actualdeparture.delayoradvance')} ${delayoradvance} ${this.$t(
							'operationsdatatable.phase.actualdeparture.delay'
						)}`;
					} else {
						visit.phasedesc = `${this.$t('operationsdatatable.phase.actualdeparture.delayoradvance')} ${delayoradvance} ${this.$t(
							'operationsdatatable.phase.actualdeparture.advance'
						)}`;
						visit.phasedescInAdvance = true;
					}
				}
			} else if (visit.delayeta) {
				// visit delayed
				const estimated = this.formatDifference(visit.eta.split(' ')[1]);
				const ms = this.$moment(this.getCurrentDateLocal(true), this.ddMMyyyHHmmFormat).diff(this.$moment(visit.eta, this.ddMMyyyHHmmFormat));
				const days = this.$moment.duration(ms);
				const hours = Math.floor(days.asHours());
				const delayed = this.formatDifference(hours + this.$moment.utc(ms).format(':mm'));
				visit.phase = `${this.$t('operationsdatatable.phase.delayedarrival')} ${estimated} (${this.$t('delay')} ${delayed})`;
			} else if (!visit.aps) {
				// visit not initialized
				const ms = this.$moment(visit.eta, this.ddMMyyyHHmmFormat).diff(this.$moment(this.getCurrentDateLocal(true), this.ddMMyyyHHmmFormat));
				const days = this.$moment.duration(ms);
				const hours = Math.floor(days.asHours());
				if (hours >= 0) {
					const hoursMinutesEstimated = this.formatDifference(hours + this.$moment.utc(ms).format(':mm'));
					visit.phase = `${this.$t('operationsdatatable.phase.estimatedarrival')} ${visit.eta} (${hoursMinutesEstimated})`;
				}
			} else {
				const currentDateLocalWithTime = this.getCurrentDateLocal(true);
				if (this.isLocalDateSameOrAfter(currentDateLocalWithTime, visit.aps)) {
					visit.phase = this.eventPhase.aps;
				}

				for (let i = 0, stopLength = visit.stops.length; i < stopLength; i++) {
					const stop = visit.stops[i];

					for (let j = 0, stopEvents = this.stopEvents.length; j < stopEvents; j++) {
						const eventName = this.stopEvents[j];
						const eventPhase = this.eventPhase[eventName];
						const eventDate = stop[eventName];

						if (!eventPhase || !eventDate) {
							continue;
						}

						if (this.isLocalDateSameOrAfter(currentDateLocalWithTime, eventDate)) {
							visit.phaseid = eventName;
							visit.phase = eventPhase;
						}
					}
				}
			}
		},
		fillVisitStatus(visit) {
			if (visit.statusid === 'AN') {
				// do nothing
			} else if (visit.etaModified || visit.stopsEtaModified) {
				if (visit.etaModified && visit.stopsEtaModified) {
					visit.statusname = this.$t('operationsdatatable.status.modifiedEtaVisitStops.title');
					visit.statusdesc = this.$t('operationsdatatable.status.modifiedEtaVisitStops.description');
				} else if (visit.etaModified) {
					visit.statusname = this.$t('operationsdatatable.status.modifiedEtaVisit.title');
					visit.statusdesc = this.$t('operationsdatatable.status.modifiedEtaVisit.description');
				} else if (visit.stopsEtaModified) {
					visit.statusname = this.$t('operationsdatatable.status.modifiedEtaStops.title');
					visit.statusdesc = this.$t('operationsdatatable.status.modifiedEtaStops.description');
				}
			} else if (visit.delayeta) {
				visit.statusname = this.$t('operationsdatatable.status.delayed.title');
				visit.statusdesc = this.$t('operationsdatatable.status.delayed.nowGreaterThanEta');
			} else if (visit.delayetc) {
				visit.statusname = this.$t('operationsdatatable.status.delayed.title');
				visit.statusdesc = this.$t('operationsdatatable.status.delayed.etcGreaterThanEtd');
			}
		},
		fillVesselStatus(visit) {
			if (visit.atd) {
				const diff = this.formatDifference(this.getDifference(visit.atd, visit.ata));
				visit.vesselstatus = `${this.$t('operationsdatatable.vesselstatus.portstay')} ${diff}`;
				visit.vesselstatusdesc = `${this.$t('operationsdatatable.vesselstatus.portstay')} ${diff}`;

				if (this.$store.getters.isCMAShippingLine) {
					const stopsMuelleEste = visit.stops.filter((stop) => {
						return stop.locationid === 51;
					});
					if (stopsMuelleEste && stopsMuelleEste.length === 1) {
						const stop = stopsMuelleEste[0];
						const cargoOperationProcess = this.formatDifference(this.getDifference(stop.fcl, stop.lcl));
						if (cargoOperationProcess) {
							visit.vesselstatus += ` ${this.$t('operationsdatatable.vesselstatus.andcargooperationprocess')} ${cargoOperationProcess}`;
							visit.vesselstatusdesc += ` ${this.$t(
								'operationsdatatable.vesselstatus.andcargooperationprocess'
							)} ${cargoOperationProcess}`;
						}
					}
				}
			} else if (visit.statusid === 'IN') {
				const port = this.$store.getters.getUserPortById(visit.portid);
				visit.vesselstatus = `${this.$t('operationsdatatable.vesselstatus.mooredin')} ${port}`;
				visit.vesselstatusdesc = `${this.$t('operationsdatatable.vesselstatus.mooredin')} (${port})`;
			} else if (visit.statusid === 'AN') {
				visit.vesselstatus = '';
				visit.vesselstatusdesc = '';
			} else {
				if (visit.fromportcode && visit.toportcode) {
					visit.vesselstatus =
						`${this.$t('operationsdatatable.vesselstatus.sailingfrom')} ${visit.fromportcode}` +
						` ${this.$t('operationsdatatable.vesselstatus.sailingto')} ${visit.toportcode}`;
					visit.vesselstatusdesc =
						`${this.$t('operationsdatatable.vesselstatus.sailingfrom')} ${visit.fromportcode} (${visit.fromportname})` +
						` ${this.$t('operationsdatatable.vesselstatus.sailingto')} ${visit.toportcode} (${visit.tomportname})`;
				}
			}
		},
		async fillVisitPreviousPortAtd(visit) {
			if (!visit.ata && visit.statusid !== 'AN') {
				let vesselLastEvent;
				// FIXME: filter service by user for not consuming all API credits!!!
				if (this.$store.getters.getUser !== 'amartinez') {
					vesselLastEvent = await MapService.getVesselLastEvent(visit.vesselimo);
				}
				if (vesselLastEvent) {
					const eventTimestampUtc = this.$moment(vesselLastEvent.timestamp).utc().format();

					if (vesselLastEvent.locode === visit.fromportcode && vesselLastEvent.event === 'DEPARTURE') {
						visit.atdfromportUtc = eventTimestampUtc;
						visit.atdfromport = this.$dateTimeUtils.getLocalFormattedDate(eventTimestampUtc, this.ddMMyyyHHmmFormat);

						const url = this.$store.getters.getModelByName('visit').url.patch;
						const params = { atdfromport: visit.atdfromport };
						const pk = { id: visit.id };
						this.$puiRequests.patchRequest(
							url,
							params,
							null,
							(error) => {
								this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
							},
							pk
						);

						visit.nextportcode = this.$store.getters.getUserPortById(visit.portid);
						visit.nextportname = this.$store.getters.getUserPortById(visit.portid);
					} else {
						visit.nextportcode = vesselLastEvent.destination;
						visit.nextportname = vesselLastEvent.destination;
					}

					visit.previousportcode = vesselLastEvent.locode;
					visit.previousportname = vesselLastEvent.port;

					if (vesselLastEvent.speed > 0) {
						visit.vesselstatus =
							`${this.$t('operationsdatatable.vesselstatus.sailingat')} ` +
							`${vesselLastEvent.speed} ${this.$t('operationsdatatable.vesselstatus.sailingknots')} ` +
							`${this.$t('operationsdatatable.vesselstatus.sailingfrom')} ${visit.previousportcode} ` +
							`${this.$t('operationsdatatable.vesselstatus.sailingto')} ${visit.nextportcode}`;
						visit.vesselstatusdesc =
							`${this.$t('operationsdatatable.vesselstatus.sailingat')} ` +
							`${vesselLastEvent.speed} ${this.$t('operationsdatatable.vesselstatus.sailingknots')} ` +
							`${this.$t('operationsdatatable.vesselstatus.sailingfrom')} ${visit.previousportcode} (${visit.previousportname}) ` +
							`${this.$t('operationsdatatable.vesselstatus.sailingto')} ${visit.nextportcode} (${visit.nextportname})`;

						const estimatedarrivalfromprediction = await this.getEstimatedArrivalFromPrediction(visit);
						if (estimatedarrivalfromprediction) {
							const etaPredicted = estimatedarrivalfromprediction.etaPredicted;
							const hoursMinutes = estimatedarrivalfromprediction.hoursMinutes;
							visit.phase = `${this.$t('operationsdatatable.phase.estimatedarrival')} ${hoursMinutes} (${etaPredicted} ${this.getAMPM(
								etaPredicted
							)})`;
							if (vesselLastEvent.event === 'DEPARTURE') {
								const visitEtaUtc = this.$moment(visit.eta, this.ddMMyyyHHmmFormat).format();
								visit.estimatedarrivalviolated = this.$moment(etaPredicted, this.ddMMyyyHHmmFormat).isAfter(visitEtaUtc);
							}
						}
					} else if (vesselLastEvent.navstatdesc === 'Moored') {
						visit.vesselstatus = `${this.$t('operationsdatatable.vesselstatus.mooredin')} ${visit.previousportcode}`;
						visit.vesselstatusdesc = `${this.$t('operationsdatatable.vesselstatus.mooredin')} ${visit.previousportcode} (${
							visit.previousportname
						})`;
						const estimateddeparturefrom = this.$dateTimeUtils.getLocalFormattedDate(eventTimestampUtc, this.ddMMyyyHHmmFormat);

						if (vesselLastEvent.event === 'ARRIVAL') {
							// Actual Time of Arrival to Previous Port
							visit.phase = `${this.$t('operationsdatatable.phase.actualarrivalto')} ${estimateddeparturefrom} ${this.getAMPM(
								estimateddeparturefrom
							)}`;
						} else if (vesselLastEvent.event === 'DEPARTURE') {
							// Estimated Time of Departure from Previous Port
							visit.phase = `${this.$t('operationsdatatable.phase.estimateddeparturefrom')} ${estimateddeparturefrom} ${this.getAMPM(
								estimateddeparturefrom
							)}`;
						}
					} else if (vesselLastEvent.navstatdesc) {
						visit.vesselstatus = `${visit.previousportcode} - ${visit.toportcode} (${vesselLastEvent.navstatdesc})`;
						visit.vesselstatusdesc = `${this.$t('operationsdatatable.vesselstatus.previousport')}: ${visit.previousportname} - ${this.$t(
							'operationsdatatable.vesselstatus.nextport'
						)}: ${visit.tomportname} (${vesselLastEvent.navstatdesc})`;
					}

					if (vesselLastEvent.event === 'ARRIVAL') {
						if (vesselLastEvent.locode === 'MAPTM') {
							// TANGER MED: tiempo travesía 2h
							const visitEtaUtc = this.$moment(visit.eta, this.ddMMyyyHHmmFormat).subtract(2, 'hours').utc().format();
							visit.estimatedarrivalviolated = this.$moment().isAfter(visitEtaUtc);
						} else if (vesselLastEvent.locode === 'MACAS') {
							// CASABLANCA: tiempo travesía 11h
							const visitEtaUtc = this.$moment(visit.eta, this.ddMMyyyHHmmFormat).subtract(11, 'hours').utc().format();
							visit.estimatedarrivalviolated = this.$moment().isAfter(visitEtaUtc);
						}
					}
				}
			}
		},
		async getEstimatedArrivalFromPrediction(visit) {
			if (visit.predictedeta) {
				visit.predictedetaWithAI = true;
				return this.getEstimatedArrivalFromPredictionInMinutes(visit, visit.predictedeta);
			}

			//let vesselInfo = await MapService.getVesselInfo(visit.vesselimo);
			let vesselInfo = await ScraperService.getVesselInfo(visit);

			if (!vesselInfo || !vesselInfo.longitude || !vesselInfo.latitude) {
				const statusMessage = this.$t('operationsvisitprediction.aisprovider.vesselnotfound');
				console.log(visit.portcallnumber, statusMessage, vesselInfo);
				return;
			}

			const fromLonLat = `${vesselInfo.longitude},${vesselInfo.latitude}`;
			const toLonLat = '-5.4476898,36.12978'; // FIXME: Hardcoded Algeciras Port position
			let distance = await MapService.getDistance(fromLonLat, toLonLat);

			if (!distance) {
				const statusMessage = this.$t('operationsvisitprediction.aisprovider.distancenotfound');
				console.log(visit.portcallnumber, statusMessage, vesselInfo);
				return;
			}

			if (!vesselInfo.speed || vesselInfo.speed < 0.9) {
				const statusMessage = `${this.$t('operationsvisitprediction.aisprovider.vesselstop')}
					${distance} ${this.$t('operationsvisitprediction.distance.miles')}`;
				console.log(visit.portcallnumber, statusMessage, vesselInfo);
				return;
			}

			const speedInMetersPerSecond = vesselInfo.speed * 0.514444;
			const timeInSeconds = distance / speedInMetersPerSecond;
			const timeInMinutes = timeInSeconds * 0.0166667;

			return this.getEstimatedArrivalFromPredictionInMinutes(visit, timeInMinutes);
		},
		getEstimatedArrivalFromPredictionInMinutes(visit, timeInMinutes) {
			if (!timeInMinutes) {
				const statusMessage = this.$t('operationsvisitprediction.etaPredictedUnknown');
				console.log(visit.portcallnumber, statusMessage, vesselInfo, distance);
				return;
			}

			const localFormat = 'DD/MM/YYYY HH:mm';
			const etaPredicted = this.$moment().add(timeInMinutes, 'minutes').format(localFormat);

			let ms = this.$moment().diff(this.$moment(etaPredicted, localFormat));
			if (ms < 0) {
				ms = this.$moment(etaPredicted, localFormat).diff(this.$moment());
			}

			const days = this.$moment.duration(ms);
			const hours = Math.floor(days.asHours());

			if (hours >= 0) {
				const hoursMinutes = hours + this.$moment.utc(ms).format(':mm');
				const hoursMinutesFormatted = hoursMinutes.replace(':', 'h') + "'";
				const statusMessage = this.$t('operationsvisitprediction.tooltip');
				console.log(visit.portcallnumber, statusMessage, hoursMinutesFormatted);
				return {
					etaPredicted: etaPredicted,
					hoursMinutes: hoursMinutesFormatted
				};
			}

			return;
		}
	}
};
