<template>
	<v-container>
		<v-row dense>
			<v-col cols="12">
				<pui-date-field :label="label" v-model="internalDate" toplabel noeditable time></pui-date-field>
			</v-col>
		</v-row>
		<v-row dense justify="center">
			<v-col cols="3">
				<v-btn small color="error" @click="subtract(30)">-{{ $t('operationsvisitedit.30m') }}</v-btn>
			</v-col>
			<v-col cols="3">
				<v-btn small color="error" @click="subtract(60)">-{{ $t('operationsvisitedit.60m') }}</v-btn>
			</v-col>
			<v-col cols="3">
				<v-btn small color="error" @click="subtract(90)">-{{ $t('operationsvisitedit.90m') }}</v-btn>
			</v-col>
			<v-col cols="3">
				<v-btn small color="error" @click="subtract(120)">-{{ $t('operationsvisitedit.120m') }}</v-btn>
			</v-col>
		</v-row>
		<v-row dense justify="center">
			<v-col cols="3">
				<v-btn small color="success" @click="add(30)">+{{ $t('operationsvisitedit.30m') }}</v-btn>
			</v-col>
			<v-col cols="3">
				<v-btn small color="success" @click="add(60)">+{{ $t('operationsvisitedit.60m') }}</v-btn>
			</v-col>
			<v-col cols="3">
				<v-btn small color="success" @click="add(90)">+{{ $t('operationsvisitedit.90m') }}</v-btn>
			</v-col>
			<v-col cols="3">
				<v-btn small color="success" @click="add(120)">+{{ $t('operationsvisitedit.120m') }}</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			required: true
		},
		date: {
			type: String
		}
	},
	data() {
		return {
			internalDate: this.date
		};
	},
	watch: {
		internalDate() {
			this.$emit('update:date', this.internalDate);
		}
	},
	methods: {
		subtract(minutes) {
			if (this.internalDate) {
				this.internalDate = this.$moment(this.internalDate)
					.subtract(minutes, 'minutes')
					.format();
			}
		},
		add(minutes) {
			if (this.internalDate) {
				this.internalDate = this.$moment(this.internalDate)
					.add(minutes, 'minutes')
					.format();
			}
		}
	}
};
</script>
