<template>
	<div class="operations-legend__block">
		<div class="circle" v-if="estimatedColor" :style="{ background: estimatedColor }"></div>
		<div class="circle" :style="{ background: realColor }"></div>
		<div>{{ title }}</div>
	</div>
</template>

<script>
export default {
	props: ['title', 'estimatedColor', 'realColor']
};
</script>
