<template>
	<div class="operations-event">
		<v-tooltip top>
			<template v-slot:activator="{ on }">
				<div v-on="on">
					<svg class="operations-event__image" width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g filter="url(#filter0_d)">
							<path
								d="M16.6975 5.02809C17.5034 5.40753 18.1266 6.09019 18.4312 6.92723L25.9052 27.4619C26.5427 29.2134 25.6396 31.1501 23.8881 31.7876L15.4459 34.8603C13.6944 35.4979 11.7577 34.5948 11.1202 32.8432L3.64617 12.3086C3.34151 11.4715 3.38011 10.548 3.75357 9.73928L6.42325 3.94048C7.20882 2.23937 9.22973 1.50382 10.925 2.30199L16.6975 5.02809Z"
								fill="white"
							/>
							<path
								d="M6.22195 3.84752L6.22184 3.84776L3.55226 9.64632C3.55224 9.64636 3.55222 9.64641 3.5522 9.64645C3.15427 10.5082 3.11315 11.4924 3.43781 12.3844L10.9118 32.9191C11.5912 34.7857 13.6551 35.7481 15.5218 35.0687L23.9639 31.996C25.8306 31.3166 26.793 29.2527 26.1136 27.3861L18.6396 6.85139C18.3149 5.95936 17.6508 5.23186 16.7919 4.82748L11.0197 2.10149L11.0194 2.10138C9.21281 1.25077 7.05913 2.03465 6.22195 3.84752Z"
								stroke="black"
								stroke-opacity="0.03"
								stroke-width="0.443463"
							/>
						</g>
						<defs>
							<filter
								id="filter0_d"
								x="0.338391"
								y="0.205683"
								width="28.8757"
								height="39.2939"
								filterUnits="userSpaceOnUse"
								color-interpolation-filters="sRGB"
							>
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
								<feOffset dy="1.33039" />
								<feGaussianBlur stdDeviation="1.33039" />
								<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
							</filter>
						</defs>
					</svg>
					<div class="operations-event__circle"></div>
					<label class="operations-event__name">{{ eventName }}</label>
				</div>
			</template>
			<span>{{ eventTooltip }}</span>
		</v-tooltip>
	</div>
</template>

<script>
export default {
	props: {
		eventName: {
			type: String,
			required: true
		},
		eventTooltip: {
			type: String,
			required: true
		}
	}
};
</script>

<style lang="postcss">
.operations-event {
	position: relative;

	&__image {
		position: absolute;
		left: 0px;
		top: 0px;
	}
	&__circle {
		position: absolute;
		left: 8px;
		top: 5px;
		width: 4px;
		height: 4px;

		background: #4fa439;
		border-radius: 50%;
	}
	&__name {
		position: absolute;
		left: 4px;
		top: 13px;

		font-size: 11px;
		color: #0c1a36;
		transform: rotate(70deg);
	}
}
</style>
