<template>
	<div>
		<v-btn icon @click="dialog = true" style="margin-right: 5px" class="elevation-0 pui-toolbar__actionBtn primary white--text">
			<v-icon>far fa-info</v-icon>
		</v-btn>
		<v-dialog v-model="dialog" width="600">
			<v-card>
				<v-card-title></v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<div v-html="$t('operationstoolbar.information')" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6">
							<img :src="require('@/assets/images/logo_itpco.png')" width="250" height="150" />
						</v-col>
						<v-col cols="6">
							<img :src="require('@/assets/images/logo_dcsa.png')" width="250" height="150" />
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="dialog = false">{{ $t('pui9.accept') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'OperationsToolbarInfo',
	data() {
		return {
			dialog: false
		};
	}
}
</script>
