<template>
	<v-dialog v-model="dialog" persistent max-width="925">
		<template v-slot:activator="{ on: dialogActivator }">
			<span v-on="{ ...dialogActivator }" class="statusname" :class="!visit.departureidletimegreaterthan45mins ? 'statusname_inadvance' : 'statusname_delayed'">
				<v-icon small class="mr-1">fas fa-history</v-icon>
				{{ visit.departureidletime }}
			</span>
		</template>

		<operations-visit-idle-time :visit="visit" v-on:close="dialog = false"/>
	</v-dialog>
</template>

<script>
import OperationsVisitIdleTime from './OperationsVisitIdleTime.vue';
export default {
	components: { OperationsVisitIdleTime },
	props: {
		visit: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			dialog: false
		};
	}
};
</script>
