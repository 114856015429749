<template>
	<v-tooltip top :disabled="!visitTooltip">
		<template v-slot:activator="{ on }">
			<div v-on="on" class="visit-column" :class="visitClass" :style="visitStyle"></div>
		</template>
		<span v-for="(label, index) in getLabelsFromTooltip()" :key="index" style="display: block">{{ label }}</span>
	</v-tooltip>
</template>

<script>
export default {
	props: {
		visitClass: {
			type: Object,
			required: true
		},
		visitStyle: {
			type: String,
			required: true
		},
		visitTooltip: {
			type: String,
			required: false,
			default: null
		}
	},
	methods: {
		getLabelsFromTooltip() {
			if (this.visitTooltip) {
				return this.visitTooltip.split(',');
			}
			return [];
		}
	}
};
</script>

<style lang="postcss" scoped>
.visit-column {
	float: left;
	&__estimated {
		height: 16px;
		margin-top: 10px;
	}
	&__real {
		height: 38px;
		margin-top: 5px;
		margin-bottom: 10px;
	}
}

.content {
	&__leftRadius {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}
	&__rightRadius {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	&__Arrival_etaetd {
		background: #f1bcf2;
	}
	&__Arrival_ataatd {
		background: #b758d9;
	}
	&__Berth_etaetd {
		background: #badd8e;
	}
	&__Berth_ataatd {
		background: #4fa439;
	}
	&__Anchored_etaetd {
		background: #afdef8;
	}
	&__Anchored_ataatd {
		background: #1f98ef;
	}
	&__Departure_etaetd {
		background: #fddeb6;
	}
	&__Departure_ataatd {
		background: #ef7217;
	}
	&__Pilotage_eta {
		background: #fde9bf;
	}
	&__Towage_eta {
		background: #f8c5be;
	}
	&__Mooring_eta {
		background: #ffd0ec;
	}
	&__Pilotage_ata {
		background: #fbbd40;
	}
	&__Towage_ata {
		background: #ed5e4a;
	}
	&__Mooring_ata {
		background: #fb6cc1;
	}
}

@media screen and (max-width: 1600px) {
	.visit-column {
		&__estimated {
			margin-top: 20px;
		}
		&__real {
			margin-top: 10px;
			margin-bottom: 20px;
		}
	}
}
</style>
