<template>
	<div ref="chartDiv" class="bar-chart" :style="{ height: heightChart }"></div>
</template>

<script>
import { create as am4core_create, color as am4core_color } from '@amcharts/amcharts4/core';
import { XYChart, CategoryAxis, ValueAxis, DurationAxis, ColumnSeries } from '@amcharts/amcharts4/charts';

export default {
	props: {
		chartData: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			heightChart: window.innerHeight / 2 - 100 + 'px',
			chart: null
		};
	},
	watch: {
		chartData() {
			if (this.chart && this.chartData) {
				this.chart.data = this.chartData;
			}
		}
	},
	mounted() {
		this.createChart();
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
	methods: {
		createChart() {
			const chart = am4core_create(this.$refs.chartDiv, XYChart);

			// Create axes
			const categoryAxis = chart.xAxes.push(new CategoryAxis());
			categoryAxis.dataFields.category = 'id';
			categoryAxis.renderer.grid.template.location = 0;
			categoryAxis.renderer.minGridDistance = 30;
			categoryAxis.renderer.labels.template.verticalCenter = 'middle';

			//const valueAxis = chart.yAxes.push(new ValueAxis());
			//valueAxis.renderer.minWidth = 50;
			//valueAxis.renderer.minHeight = 300;
			const valueAxis = chart.yAxes.push(new DurationAxis());
			valueAxis.baseUnit = 'minute';
			//valueAxis.title.text = 'Duration';
			chart.durationFormatter.durationFormat = "hh'h'mm'''";

			// Create series
			const series = chart.series.push(new ColumnSeries());
			series.sequencedInterpolation = true;
			series.dataFields.valueY = 'minutes';
			series.dataFields.categoryX = 'id';
			series.columns.template.tooltipText = '[bold]{name}[/]: {time}';
			series.columns.template.strokeWidth = 0;

			series.columns.template.stroke = am4core_color('#ffffff');
			series.columns.template.propertyFields.fill = 'color'; // get color from chartData

			series.tooltip.pointerOrientation = 'vertical';

			series.columns.template.column.cornerRadiusTopLeft = 10;
			series.columns.template.column.cornerRadiusTopRight = 10;
			series.columns.template.column.fillOpacity = 0.8;

			// on hover, make corner radiuses bigger
			const hoverState = series.columns.template.column.states.create('hover');
			hoverState.properties.cornerRadiusTopLeft = 0;
			hoverState.properties.cornerRadiusTopRight = 0;
			hoverState.properties.fillOpacity = 1;

			chart.data = this.chartData;
			this.chart = chart;
		}
	}
};
</script>

<style scoped>
.bar-chart {
	width: 100%;
	margin: 1em 0;
}
