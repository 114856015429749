<template>
	<v-data-table class="operations-visit-detail-history-visits-table" :headers="headers" :items="visits" :custom-sort="customSort" hide-default-footer>
		<template v-slot:header="{ props: {} }">
			<thead>
				<tr>
					<th></th>
					<th colspan="3" class="header__arrival">{{ $t('operationsvisitdetailhistoryvisits.arrival') }}</th>
					<th colspan="3" class="header__departure">{{ $t('operationsvisitdetailhistoryvisits.departure') }}</th>
				</tr>
			</thead>
		</template>
		<template v-slot:item.diffEtaAtaFormatted="{ item }">
			<v-chip :color="item.diffEtaAtaColor" label dark>{{ item.diffEtaAtaFormatted }}</v-chip>
		</template>
		<template v-slot:item.diffEtdAtdFormatted="{ item }">
			<v-chip :color="item.diffEtdAtdColor" label dark>{{ item.diffEtdAtdFormatted }}</v-chip>
		</template>
	</v-data-table>
</template>

<script>
export default {
	props: {
		visits: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			headers: [
				{ text: this.$t('operationsvisitdetailhistoryvisits.portcallnumber'), value: 'portcallnumber' },
				{ text: this.$t('operationsvisitdetailhistoryvisits.eta'), value: 'eta' },
				{ text: this.$t('operationsvisitdetailhistoryvisits.ata'), value: 'ata' },
				{ text: this.$t('operationsvisitdetailhistoryvisits.diffetaata'), value: 'diffEtaAtaFormatted', align: 'center' },
				{ text: this.$t('operationsvisitdetailhistoryvisits.etd'), value: 'etd' },
				{ text: this.$t('operationsvisitdetailhistoryvisits.atd'), value: 'atd' },
				{ text: this.$t('operationsvisitdetailhistoryvisits.diffetdatd'), value: 'diffEtdAtdFormatted', align: 'center' }
			]
		};
	},
	methods: {
		customSort(items, index, isDesc) {
			items.sort((a, b) => {
				if (index[0] === 'eta' || index[0] === 'ata' || index[0] === 'etd' || index[0] === 'atd') {
					if (!isDesc[0]) {
						return a[`${index}Comparable`].toLowerCase().localeCompare(b[`${index}Comparable`].toLowerCase());
					} else {
						return b[`${index}Comparable`].toLowerCase().localeCompare(a[`${index}Comparable`].toLowerCase());
					}
				} else {
					if (typeof a[index] !== 'undefined') {
						if (!isDesc[0]) {
							return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
						} else {
							return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
						}
					}
				}
			});
			return items;
		}
	}
};
</script>

<style lang="postcss">
.operations-visit-detail-history-visits-table {
	& .header__arrival {
		text-align: center !important;
		border-bottom: 2px solid #b758d9 !important;
		color: #b758d9 !important;
	}
	& .header__departure {
		text-align: center !important;
		border-bottom: 2px solid #ef7217 !important;
		color: #ef7217 !important;
	}
	tbody tr:nth-of-type(even) {
		background-color: #f5f7f7 !important;
	}
}
</style>
