<template>
	<v-container>
		<v-expansion-panels>
			<v-expansion-panel class="timeline-summary-expansion-panel">
				<v-expansion-panel-header color="primary" class="white--text">
					<div class="v-expansion-panel-header__icon mr-2">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn text icon v-on="on" @click.stop="showVesselHistoryDialog = true">
									<v-icon style="transform: none">fas fa-history</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('operationsvisitdetail.visits.history') }}</span>
						</v-tooltip>
					</div>
					<span>{{ $t('operationsvisitdetailtimelinesummary.portcallsummary') + getDateFromLastMilestone() }}</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content color="primary" class="white--text">
					<div v-for="(detail, index) in visitSummaryDetails" :key="index">
						<span v-if="index < visitSummaryDetails.length - 1">{{ detail }}</span>
						<span v-else
							><strong>{{ detail }}</strong></span
						>
					</div>
					<br />
					<div v-for="(stopDetails, index) in stopsSummaryDetails" :key="'stops' + index">
						<div v-for="(detail, index2) in stopDetails" :key="index2">
							<span v-if="index2 < stopDetails.length - 1">{{ detail }}</span>
							<span v-else
								><strong>{{ detail }}</strong></span
							>
						</div>
						<br />
					</div>
					<div v-if="berthCollision">
						<v-icon class="mr-2">fas fa-exclamation</v-icon><span>{{ $t('operationsvisitdetailtimelinesummary.berthcollision') }}</span>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-dialog v-model="showVesselHistoryDialog" max-width="1200">
			<operations-visit-detail-history :visit="visit" v-on:close-dialog="showVesselHistoryDialog = false"></operations-visit-detail-history>
		</v-dialog>
	</v-container>
</template>

<script>
import OperationsVisitDetailHistory from '../history/OperationsVisitDetailHistory.vue';
import DifferenceMixin from '@/mixins/HHmmDifferenceMixin';
import TimelineMixin from '@/mixins/TimelineMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';

export default {
	components: { OperationsVisitDetailHistory },
	mixins: [DifferenceMixin, TimelineMixin, DateTimeMixin],
	props: {
		visit: {
			type: Object,
			required: true
		},
		milestones: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			showVesselHistoryDialog: false,
			visitSummaryDetails: [],
			stopsSummaryDetails: []
		};
	},
	computed: {
		berthCollision() {
			const stops = this.visit.stops;
			for (let i = 0, stopsLength = stops.length; i < stopsLength; i++) {
				const stopI = stops[i];
				for (let j = 0; j < stopsLength; j++) {
					const stopJ = stops[j];
					if (stopI.num === stopJ.num) {
						continue;
					}
					if (
						(this.isLocalDateSameOrBefore(stopI.eta, stopJ.eta) && this.isLocalDateSameOrAfter(stopI.etd, stopJ.eta)) ||
						(this.isLocalDateSameOrBefore(stopI.eta, stopJ.etd) && this.isLocalDateSameOrAfter(stopI.etd, stopJ.etd))
					) {
						return true;
					}
				}
			}
			return false;
		}
	},
	created() {
		this.setVisitSummaryDetails();
		this.setStopsSummaryDetails();
	},
	methods: {
		setVisitSummaryDetails() {
			let initDate, endDate;
			if (this.visit.ata) {
				initDate = this.getDateAMPM(this.visit.ata);
				this.visitSummaryDetails.push(`${this.$t('operationsvisitdetailtimelinesummary.atapilotboarding')} ${initDate}`);
			} else {
				initDate = this.getDateAMPM(this.visit.eta);
				this.visitSummaryDetails.push(`${this.$t('operationsvisitdetailtimelinesummary.etapilotboarding')} ${initDate}`);
			}

			if (this.visit.atd) {
				endDate = this.getDateAMPM(this.visit.atd);
				this.visitSummaryDetails.push(`${this.$t('operationsvisitdetailtimelinesummary.atdpilotboarding')} ${endDate}`);
			} else {
				endDate = this.getDateAMPM(this.visit.etd);
				this.visitSummaryDetails.push(`${this.$t('operationsvisitdetailtimelinesummary.etdpilotboarding')} ${endDate}`);
			}

			this.visitSummaryDetails.push(
				`${this.$t('operationsvisitdetailtimelinesummary.estimatedportstay')} ${this.getETAETDFormatDiffDate(endDate, initDate, true)}`
			);
		},
		setStopsSummaryDetails() {
			const stops = this.visit.stops;
			for (let i = 0, stopsLength = stops.length; i < stopsLength; i++) {
				const stop = stops[i];
				const stopDetails = [];
				let initDate, endDate;
				let berthOrAnchorage = stop.isanchorage
					? this.$t('operationsvisitdetailtimelinesummary.anchored')
					: this.$t('operationsvisitdetailtimelinesummary.berth');

				if (stop.ata) {
					initDate = this.getDateAMPM(stop.ata);
					stopDetails.push(`ATA ${berthOrAnchorage}: ${initDate}`);
				} else {
					initDate = this.getDateAMPM(stop.eta);
					stopDetails.push(`ETA ${berthOrAnchorage}: ${initDate}`);
				}
				if (stop.atd) {
					endDate = this.getDateAMPM(stop.atd);
					stopDetails.push(`ATD ${berthOrAnchorage}: ${endDate}`);
				} else {
					endDate = this.getDateAMPM(stop.etd);
					stopDetails.push(`ETD ${berthOrAnchorage}: ${endDate}`);
				}
				stopDetails.push(
					`${this.$t('operationsvisitdetailtimelinesummary.estimatedstay')} ${berthOrAnchorage}: ${this.getETAETDFormatDiffDate(
						endDate,
						initDate,
						true
					)}`
				);
				this.stopsSummaryDetails.push(stopDetails);
			}
		},
		getPanelHeader() {},
		getDateAMPM(date) {
			return this.showAMPM(date);
		},
		getETAETDFormatDiffDate(etd, eta, previousFormated) {
			if (previousFormated) {
				const diffport = this.getDifference(etd, eta);
				return this.formatDifference(diffport);
			} else {
				const dateTimeFormattedEtd = this.$dateTimeUtils.getLocalFormattedDate(etd, this.diffDateTimeFormat);
				const dateTimeFormattedEta = this.$dateTimeUtils.getLocalFormattedDate(eta, this.diffDateTimeFormat);
				const diffport = this.getDifference(dateTimeFormattedEtd, dateTimeFormattedEta);
				return this.formatDifference(diffport);
			}
		},
		getDateFromLastMilestone() {
			if (this.milestones.length > 0) {
				const lastMilestone = this.milestones[0];
				const lastMilestoneDate = this.$dateTimeUtils.getLocalFormattedDate(lastMilestone.date, this.dateTimeFormat);
				const lastUpdate = this.showAMPM(lastMilestoneDate);
				return lastUpdate ? ` ( ${this.$t('operationsvisitdetailtimelinesummary.lastupdate')} ${lastUpdate} )` : '';
			}
			return '';
		}
	}
};
</script>
