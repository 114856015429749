<template>
	<div class="operations-visit-detail-timeline">
		<operations-visit-detail-timeline-toolbar
			@toggleBtnFiltered="toggleBtnFiltered"
			@verboseSelected="verboseSelected"
			@generatePDFClicked="generateTextToPdf"
		/>
		<v-divider />
		<v-row dense>
			<v-col>
				<v-text-field
					ref="searchingText"
					hide-details
					:placeholder="$t('operationstoolbar.search')"
					v-model="searchingText"
					prepend-inner-icon="far fa-search"
					outlined
					rounded
				></v-text-field>
			</v-col>
		</v-row>
		<div class="pb-1" v-if="!milestonesLoaded">
			<v-progress-linear indeterminate />
		</div>
		<v-layout class="operations-visit-detail-timeline__separator" />

		<div class="operations-visit-detail-timeline__timeline" ref="timeline">
			<operations-visit-detail-timeline-summary v-if="milestonesLoaded" :visit="visit" :milestones="milestonesFiltered" />
			<v-container class="px-4" v-if="milestonesNoDataMessage">
				<v-row dense>
					<v-col cols="12">
						<span>{{ milestonesNoDataMessage }}</span>
					</v-col>
				</v-row>
			</v-container>
			<v-list v-for="(date, index) in arrDates" :key="index">
				<v-layout nowrap v-if="getMilestonesFilteredByDate(date).length > 0">
					<v-flex md9 sm9 xs9 class="text-center pb-2">
						<strong>{{ getLongDate(date) }}</strong>
					</v-flex>
				</v-layout>
				<div>
					<v-timeline dense v-if="getMilestonesFilteredByDate(date).length > 0" style="margin-right: 10px">
						<v-timeline-item
							v-for="(milestone, index) in getMilestonesFilteredByDate(date)"
							:key="index"
							class="pb-2"
							:color="milestone.color"
							:icon="milestone.icon"
							:small="milestone.iconSmall || !milestone.icon"
							fill-dot
							right
						>
							<template slot="icon">
								<v-tooltip right>
									<template v-slot:activator="{ on }">
										<v-icon v-on="on" dark :small="milestone.iconSmall || !milestone.icon">{{ milestone.icon }}</v-icon>
									</template>
									<span>{{ milestone.icondesc }}</span>
								</v-tooltip>
							</template>
							<v-card flat slot="opposite" class="opposite-width-force">
								<v-list dense>
									<v-list-item class="justify-end timeline-list-dense px-0">
										<span>{{ getLongTime(milestone.dateFormatted) }}</span>
									</v-list-item>

									<v-list-item class="justify-end timeline-list-dense px-0">
										<span>
											<strong>{{ milestone.portcall }}</strong>
										</span>
									</v-list-item>

									<v-list-item class="justify-end timeline-list-dense px-0" v-if="hasDataQualityCompliance(milestone)">
										<v-tooltip right>
											<template v-slot:activator="{ on }">
												<v-flex xs12>
													<span v-on="on"><i class="fas fa-check-circle" style="color: #1f98ef"></i></span>
												</v-flex>
											</template>
											<span>Data Quality Compliance</span>
										</v-tooltip>
									</v-list-item>
								</v-list>
							</v-card>
							<v-card outlined class="rounded-lg" :style="`border: 2px solid ${milestone.color};`">
								<v-card-text class="pa-1 pt-1" v-if="milestone.detailsFormatted && milestone.detailsFormatted.length">
									<div
										v-for="(detail, detailIndex) in milestone.detailsFormatted"
										:key="detailIndex"
										class="operations-visit-detail-timeline__timelinedetail--pre-formatted"
									>
										<span>{{ detail }}</span>
									</div>
								</v-card-text>
							</v-card>
						</v-timeline-item>
					</v-timeline>
				</div>
			</v-list>
		</div>
	</div>
</template>

<script>
import jsPDF from 'jspdf';
import OperationsVisitDetailTimelineToolbar from './OperationsVisitDetailTimelineToolbar.vue';
import OperationsVisitDetailTimelineSummary from './OperationsVisitDetailTimelineSummary.vue';
import DifferenceMixin from '@/mixins/HHmmDifferenceMixin';
import EventsMixin from '@/mixins/EventsMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import TimelineMixin from '@/mixins/TimelineMixin';

const dateTimeRegex = /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(.[0-9]+)?Z/g;

export default {
	components: { OperationsVisitDetailTimelineToolbar, OperationsVisitDetailTimelineSummary },
	mixins: [DifferenceMixin, EventsMixin, DateTimeMixin, TimelineMixin],
	props: {
		visit: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			reqController: '/visit/getVisitInfo',
			reqParams: { id: this.visit.id },
			dateTimeFormatMilestone: 'DD/MM HH:mm:ss.SSS',
			milestonesDefault: [],
			milestones: [],
			milestonesFiltered: [],
			milestonesLoaded: false,
			milestonesNoDataMessage: null,
			arrDates: [],
			searchingText: null,
			visitTypeProperties: {
				FROMPORTCODE: this.$t('operationsvisitdetailtimeline.visitproperties.fromportcode'),
				TOPORTCODE: this.$t('operationsvisitdetailtimeline.visitproperties.toportcode')
			},
			stopTypeProperties: {
				BERTHINGPOSITION: this.$t('operationsvisitdetailtimeline.stopproperties.berthingposition'),
				LOCATIONNAME: this.$t('operationsvisitdetailtimeline.stopproperties.locationname'),
				BOLLARDINICODE: this.$t('operationsvisitdetailtimeline.stopproperties.bollardinicode'),
				BOLLARDENDCODE: this.$t('operationsvisitdetailtimeline.stopproperties.bollardendcode'),
				BOLLARDINITERMINAL: this.$t('operationsvisitdetailtimeline.stopproperties.bollardiniterminal'),
				BOLLARDENDTERMINAL: this.$t('operationsvisitdetailtimeline.stopproperties.bollardendterminal'),
				ATDTERMINAL: this.$t('operationsvisitdetailtimeline.stopproperties.atdterminal')
			},
			serviceTypeProperties: {
				REQUESTCOMPANYNAME: this.$t('operationsvisitdetailtimeline.serviceproperties.requestcompanyname'),
				STARTTIMEESTIMATED: this.$t('operationsvisitdetailtimeline.serviceproperties.starttimeestimated'),
				ENDTIMEESTIMATED: this.$t('operationsvisitdetailtimeline.serviceproperties.endtimeestimated'),
				STARTTIMEREAL: this.$t('operationsvisitdetailtimeline.serviceproperties.starttimereal'),
				ENDTIMEREAL: this.$t('operationsvisitdetailtimeline.serviceproperties.endtimereal')
			},
			visitExcludeProperties: ['STATUS'],
			stopExcludeProperties: ['STATUS', 'LOCATIONID', 'ATC', 'BOLLARDINI', 'BOLLARDEND'],
			serviceExcludeProperties: ['STATUS', 'REQUESTCOMPANY'],
			showAllVerbose: false,
			firstEtcInserted: false,
			bollarIniTerminal: null,
			bollarEndTerminal: null,
			milestoneReached: ['FCL', 'LCL'],
			eventsFromPortAuthority: ['ATA', 'ATD', 'PBA', 'FLA', 'RTS', 'PBD', 'LLA'],
			messagesFromShipping: ['FROMPORTCODE', 'TOPORTCODE'],
			messagesFromTerminal: [
				'ETATERMINAL',
				'ETDTERMINAL',
				'ATATERMINAL',
				'ATDTERMINAL',
				'BOLLARDINITERMINAL',
				'BOLLARDENDTERMINAL',
				'RTSTERMINAL'
			]
		};
	},
	created() {
		this.getVisitInfo();
	},
	watch: {
		searchingText(newVal) {
			this.search();
		}
	},
	methods: {
		toggleBtnFiltered(value) {
			this.toggleBtnFilterByIcon = value;
			this.search();
		},
		verboseSelected(value) {
			this.showAllVerbose = value;
			setTimeout(() => {
				this.searchingText = '';
				this.milestonesLoaded = false;
				this.firstEtcInserted = false;
				if (this.milestonesDefault) {
					this.processMilestones(this.milestonesDefault);
				} else {
					this.milestonesNoDataMessage = this.$t('operationsvisitdetailtimeline.noevents');
				}
				this.milestonesLoaded = true;
			}, 1000); // 1 sec delay
		},
		search() {
			if (!this.awaitingSearch) {
				setTimeout(() => {
					this.searchMilestones(this.searchingText);
					this.awaitingSearch = false;
				}, 1000); // 1 sec delay
			}
			this.awaitingSearch = true;
		},
		getStopNumber(id) {
			if (this.visit.stops.length > 1) {
				const stopsFiltered = this.visit.stops.filter((stop) => {
					return stop.id === id;
				});
				if (stopsFiltered && stopsFiltered.length === 1) {
					return stopsFiltered[0].num;
				}
			}
			return '';
		},
		getStopType(id, evt) {
			let stopType = '';
			if (evt === 'ETA' || evt === 'ETD') {
				const stopsFiltered = this.visit.stops.filter((stop) => {
					return stop.id === id;
				});
				if (stopsFiltered && stopsFiltered.length === 1) {
					stopType = stopsFiltered[0].isanchorage
						? this.$t('operationsvisitdetailtimelinesummary.anchored')
						: this.$t('operationsvisitdetailtimelinesummary.berth');
				}
			} else {
				stopType = this.$t('operationsvisitdetailtimelinesummary.berth');
			}
			return stopType;
		},
		toCapitalFirst(value) {
			if (!value) return '';
			value = value.toString().toLowerCase();
			const arrPalabras = value.split(' ');
			const numPalabras = arrPalabras.length;
			let result = '';
			for (let i = 0; i < numPalabras; i++) {
				result += arrPalabras[i].charAt(0).toUpperCase() + arrPalabras[i].slice(1) + ' ';
			}
			return result;
		},
		getLongDate(date) {
			let difDate = this.$moment(date).locale(window.localStorage.getItem('pui9_lang'));
			return date ? (date == this.getCurrentDate() ? this.$t('operationsvisitdetailtimeline.today') : difDate.format('dddd, D MMMM')) : date;
		},
		getLongTime(date) {
			return `${date.substr(6, 8)} ${date.substr(6, 2) < 12 ? 'am' : 'pm'}`;
		},
		getMilestonesFilteredByDate(date) {
			const dateDDMM = this.$moment(date).format('DD/MM');
			return this.milestonesFiltered.filter((milestone) => {
				return milestone.dateFormatted.substr(0, 5).includes(dateDDMM);
			});
		},
		getEventDescription(event) {
			return event != null ? `${this.allEventsDescription[event.toLowerCase()]}` : '';
		},
		getEventName(event) {
			return event != null ? `${this.allEventsName[event.toLowerCase()]}` : '';
		},
		getDataOwner(event) {
			return event != null ? `Data Owner: ${this.eventOrigin[event.toLowerCase()]}` : '';
		},
		collectDataQualityComplianceForProperty(milestone, type, collect, property) {
			if (!this[collect]) {
				this[collect] = {
					dataQualityCompliance: type,
					milestoneIds: [milestone.auditId]
				};
			} else if (type === property) {
				if (this[collect].dataQualityCompliance !== type) {
					this[collect].milestoneIds = []; // reset array
				}
				this[collect].dataQualityCompliance = type;
				this[collect].milestoneIds.push(milestone.auditId);
			}
		},
		collectDataQualityCompliance(milestone, detail) {
			const value = detail.value;
			const type = detail.type;
			if (value && (type === 'ETA' || type === 'ETATERMINAL')) {
				this.collectDataQualityComplianceForProperty(milestone, type, 'etaBerth', 'ETATERMINAL');
			}
			if (value && (type === 'ETD' || type === 'ETDTERMINAL')) {
				this.collectDataQualityComplianceForProperty(milestone, type, 'etdBerth', 'ETDTERMINAL');
			}
			if (value && (type === 'BOLLARDINI' || type === 'BOLLARDINITERMINAL')) {
				this.collectDataQualityComplianceForProperty(milestone, type, 'bollardIni', 'BOLLARDINITERMINAL');
			}
			if (value && (type === 'BOLLARDEND' || type === 'BOLLARDENDTERMINAL')) {
				this.collectDataQualityComplianceForProperty(milestone, type, 'bollardEnd', 'BOLLARDENDTERMINAL');
			}
			if (value && (type === 'ATA' || type === 'ATATERMINAL')) {
				this.collectDataQualityComplianceForProperty(milestone, type, 'ataBerth', 'ATATERMINAL');
			}
			if (value && (type === 'ATD' || type === 'ATDTERMINAL')) {
				this.collectDataQualityComplianceForProperty(milestone, type, 'atdBerth', 'ATDTERMINAL');
			}
		},
		hasDataQualityCompliance(milestone) {
			if (this.etaBerth && this.etaBerth.milestoneIds.includes(milestone.auditId)) {
				return true;
			}
			if (this.etdBerth && this.etdBerth.milestoneIds.includes(milestone.auditId)) {
				return true;
			}
			if (this.bollardIni && this.bollardIni.milestoneIds.includes(milestone.auditId)) {
				return true;
			}
			if (this.bollardEnd && this.bollardEnd.milestoneIds.includes(milestone.auditId)) {
				return true;
			}
			if (this.ataBerth && this.ataBerth.milestoneIds.includes(milestone.auditId)) {
				return true;
			}
			if (this.atdBerth && this.atdBerth.milestoneIds.includes(milestone.auditId)) {
				return true;
			}
			return false;
		},
		async getVisitInfo() {
			try {
				const { data: { milestones } = {} } = await this.$puiRequests.getRequest(this.reqController, this.reqParams);

				if (milestones) {
					this.milestonesDefault = milestones;
					this.processMilestones(milestones);
					//this.getTimelineSummary(milestones);
				} else {
					this.milestonesNoDataMessage = this.$t('operationsvisitdetailtimeline.noevents');
				}
			} catch (error) {
				console.error(error);
				this.milestonesNoDataMessage = this.$t('operationsvisitdetailtimeline.noevents');
			} finally {
				this.milestonesLoaded = true;
			}
		},
		processMilestones(_milestones) {
			let lastDate = null;
			const tempMilestones = [];

			const milestones = [..._milestones].sort((a, b) => (b.date > a.date ? 1 : -1)); // orden descendente

			for (let i = 0, milestonesLength = milestones.length; i < milestonesLength; i++) {
				const milestone = milestones[i];
				this.formatMilestone(milestone);

				if (!milestone.detailsFormatted || milestone.detailsFormatted.length === 0) {
					continue;
				}

				// remove duplicates
				milestone.detailsFormatted = milestone.detailsFormatted.filter(function (item, pos, self) {
					return self.indexOf(item) == pos;
				});

				if (lastDate === null) {
					tempMilestones.push(milestone);
					lastDate = milestone.dateFormatted;
				} else if (lastDate !== milestone.dateFormatted) {
					tempMilestones.push(milestone);
					lastDate = milestone.dateFormatted;
				} else if (milestone.detailsFormatted) {
					let lastMilestone = tempMilestones.pop();
					const milestoneDetailsFormattedLength = milestone.detailsFormatted.length;
					for (let j = 0; j < milestoneDetailsFormattedLength; j++) {
						if (!lastMilestone.detailsFormatted) {
							lastMilestone.detailsFormatted = [];
						}
						lastMilestone.detailsFormatted.push(milestone.detailsFormatted[j]);
					}

					lastMilestone.detailsFormatted = lastMilestone.detailsFormatted.filter(function (item, pos, self) {
						return self.indexOf(item) == pos;
					});
					tempMilestones.push(lastMilestone);
				}

				this.addPreviousPortAtdMilestone(tempMilestones);
				this.afterFormatMilestone(tempMilestones);

				this.arrDates.push(milestone.date.substr(0, 10));
			}

			// remove duplicates
			this.arrDates = this.arrDates.filter(function (item, pos, self) {
				return self.indexOf(item) == pos;
			});
			//this.arrDates.sort((a, b) => (b > a ? 1 : -1));

			this.milestones = tempMilestones;
			this.milestonesFiltered = this.milestones.slice(0);

			if (this.milestonesFiltered.length === 0) {
				this.milestonesNoDataMessage = this.$t('operationsvisitdetailtimeline.noevents');
			} /*else {
				this.milestonesFiltered.sort((a, b) => (b.date > a.date ? 1 : -1)); // orden descendente
			}*/
		},
		formatMilestone(milestone) {
			milestone.dateFormatted = this.$dateTimeUtils.getLocalFormattedDate(milestone.date, this.dateTimeFormatMilestone);
			milestone.color = this.icons.shippingLine.color;

			if (milestone.type === 'VISIT') {
				this.formatMilestoneVisit(milestone);
			} else if (milestone.type === 'STOP') {
				this.formatMilestoneStop(milestone);
			} else if (milestone.type === 'FROMPORT') {
				this.formatMilestoneFromPort(milestone);
			} else {
				//this.formatMilestoneService(milestone);
			}
		},
		formatMilestoneVisit(milestone) {
			const detailsFormatted = [];

			if (milestone.isCreation) {
				milestone.portcall = this.$t('visit.statusid.rq.portcall');

				for (let i = 0, detailsLength = milestone.details.length; i < detailsLength; i++) {
					const detail = milestone.details[i];
					if (typeof detail.value === 'string' && detail.value.match(dateTimeRegex)) {
						if (this.eventsFromPortAuthority.includes(detail.type)) {
							milestone.icon = this.icons.portAuthority.icon;
							milestone.icondesc = this.icons.portAuthority.desc;
							milestone.color = this.icons.portAuthority.color;
						} else if (this.messagesFromTerminal.includes(detail.type)) {
							milestone.icon = this.icons.containerTerminal.icon;
							milestone.icondesc = this.icons.containerTerminal.desc;
							milestone.color = this.icons.containerTerminal.color;
							//milestone.portcall = 'Port Call Updated';
						} else {
							milestone.icon = this.icons.shippingLine.icon;
							milestone.icondesc = this.icons.shippingLine.desc;
							milestone.color = this.icons.shippingLine.color;
						}

						if (detail.type === 'ETA') {
							let dateSubstract = this.$moment(detail.value).subtract(2, 'hours').format();
							const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(dateSubstract, this.dateTimeFormat);
							detailsFormatted.push(
								`${detail.type} ${this.$t('operationsvisitdetailtimeline.pilotboardingplace')}: ${this.showAMPM(dateTimeFormatted)}`
							);
						} else if (detail.type === 'ETD') {
							let dateSubstract = this.$moment(detail.value).add(2, 'hours').format();
							const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(dateSubstract, this.dateTimeFormat);
							detailsFormatted.push(
								`${detail.type} ${this.$t('operationsvisitdetailtimeline.pilotboardingplace')}: ${this.showAMPM(dateTimeFormatted)}`
							);
						} else if (detail.type === 'ATA') {
							const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(detail.value, this.dateTimeFormat);
							detailsFormatted.push(`${this.$t('operationsvisitdetailtimeline.pilotata')}: ${this.showAMPM(dateTimeFormatted)}`);
						} else if (detail.type != 'ATDFROMPORT') {
							const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(detail.value, this.dateTimeFormat);
							detailsFormatted.push(
								`${detail.type} ${this.$t('operationsvisitdetailtimeline.pilotboardingplace')}: ${this.showAMPM(dateTimeFormatted)}`
							);
						}
					} else {
						if ((detail.type === 'FROMPORTCODE' || detail.type === 'TOPORTCODE') && detail.value && detail.value != 'ZZZZZ') {
							detailsFormatted.push(`${this.visitTypeProperties[detail.type] || detail.type}: ${detail.value}`);
						}
					}
				}
			} else {
				let portCallModifiedByTheAgent = false;
				for (let i = 0, detailsLength = milestone.details.length; i < detailsLength; i++) {
					const detail = milestone.details[i];
					if (detail.type === 'STATUS') {
						milestone.icon = this.icons.portAuthority.icon;
						milestone.icondesc = this.icons.portAuthority.desc;
						milestone.color = this.icons.portAuthority.color;
						if (detail.value !== 'ACCEPTED') {
							milestone.portcall = `${this.$t(`visit.statusid.${detail.value.toLowerCase()}.portcall`)}`;
							detailsFormatted.push(`${this.$t(`visit.statusid.${detail.value.toLowerCase()}.portcall`)}`);
						}
						if (detail.value === 'CONFIRMED') {
							let datePlusHour = this.$moment(milestone.date).add(1, 'hours').add(30, 'minutes').format();
							const datePlusHourFormatted = this.$dateTimeUtils.getLocalFormattedDate(datePlusHour, this.dateTimeFormat);
							milestone.additionalInfo = {
								icon: this.icons.warning.icon,
								icondesc: this.icons.warning.desc,
								iconSmall: true,
								color: this.icons.warning.color,
								dateFormatted: milestone.dateFormatted,
								detailsFormatted: [`Expected arrival at ${datePlusHourFormatted}`]
							};
						}
					} else if (detail.type === 'APS' || detail.type === 'POF') {
						milestone.icon = this.icons.portAuthority.icon;
						milestone.icondesc = this.icons.portAuthority.desc;
						milestone.color = this.icons.portAuthority.color;
						milestone.portcall = this.$t('operationsvisitdetailtimeline.portcallupdated');
						detailsFormatted.push(`${this.getEventDescription(detail.type)}`);
					} else if (detail.type === 'PORTCALLNUMBER') {
						milestone.portcall = this.$t('visit.statusid.ac.portcall');
						detailsFormatted.push(`Port Call Number ${detail.value}`);
					} else if (detail.type === 'DELAYETA') {
						milestone.icon = this.icons.warning.icon;
						milestone.icondesc = this.icons.warning.desc;
						milestone.color = this.icons.warning.color;
						milestone.iconSmall = true;
						detailsFormatted.push(this.$t('operationsvisitdetailtimeline.status.delayed.nowGreaterThanEta'));
					} else if (detail.type === 'DELAYETC') {
						milestone.icon = this.icons.warning.icon;
						milestone.icondesc = this.icons.warning.desc;
						milestone.color = this.icons.warning.color;
						milestone.iconSmall = true;
						detailsFormatted.push(this.$t('operationsdatatable.status.delayed.etcGreaterThanEtd'));
					} else if (detail.type === 'BERTHCOLLISION') {
						milestone.icon = this.icons.warning.icon;
						milestone.icondesc = this.icons.warning.desc;
						milestone.color = this.icons.warning.color;
						milestone.iconSmall = true;
						detailsFormatted.push(this.$t('operationsdatatable.status.berthcollision'));
					} else if (detail.type === 'BOLLARDINITERMINAL' || detail.type === 'BOLLARDENDTERMINAL') {
						let newvalue = !isNaN(detail.value) ? Math.ceil(detail.value) : '';
						detailsFormatted.push(`${this.stopTypeProperties[detail.type] || detail.type}: ${newvalue}`);
					} else if (typeof detail.value === 'string' && detail.value.match(dateTimeRegex)) {
						if (!portCallModifiedByTheAgent) {
							portCallModifiedByTheAgent = true;
							milestone.portcall = this.$t('operationsvisitdetailtimeline.portcallupdated');
							milestone.icon = this.icons.shippingLine.icon;
							milestone.icondesc = this.icons.shippingLine.desc;
							milestone.color = this.icons.shippingLine.color;
						} else {
							if (this.eventsFromPortAuthority.includes(detail.type)) {
								milestone.icon = this.icons.portAuthority.icon;
								milestone.icondesc = this.icons.portAuthority.desc;
								milestone.color = this.icons.portAuthority.color;
							} else if (this.messagesFromTerminal.includes(detail.type)) {
								milestone.icon = this.icons.containerTerminal.icon;
								milestone.icondesc = this.icons.containerTerminal.desc;
								milestone.color = this.icons.containerTerminal.color;
								milestone.portcall = this.$t('operationsvisitdetailtimeline.portcallupdated');
							} else {
								milestone.icon = this.icons.shippingLine.icon;
								milestone.icondesc = this.icons.shippingLine.desc;
								milestone.color = this.icons.shippingLine.color;
							}
						}

						if (detail.type === 'ETA') {
							let dateSubstract = this.$moment(detail.value).subtract(2, 'hours').format();
							const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(dateSubstract, this.dateTimeFormat);
							detailsFormatted.push(
								`${detail.type} ${this.$t('operationsvisitdetailtimeline.pilotboardingplace')}: ${this.showAMPM(dateTimeFormatted)}`
							);
						} else if (detail.type === 'ETD') {
							let dateSubstract = this.$moment(detail.value).add(2, 'hours').format();
							const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(dateSubstract, this.dateTimeFormat);
							detailsFormatted.push(
								`${detail.type} ${this.$t('operationsvisitdetailtimeline.pilotboardingplace')}: ${this.showAMPM(dateTimeFormatted)}`
							);
						} else if (detail.type === 'ATA') {
							const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(detail.value, this.dateTimeFormat);
							detailsFormatted.push(`${this.$t('operationsvisitdetailtimeline.pilotata')}: ${this.showAMPM(dateTimeFormatted)}`);
						} else if (detail.type != 'ATDFROMPORT') {
							const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(detail.value, this.dateTimeFormat);
							detailsFormatted.push(
								`${detail.type} ${this.$t('operationsvisitdetailtimeline.pilotboardingplace')}: ${this.showAMPM(dateTimeFormatted)}`
							);
						}
					} else {
						milestone.icon = this.icons.shippingLine.icon;
						milestone.icondesc = this.icons.shippingLine.desc;
						milestone.color = this.icons.shippingLine.color;
						milestone.portcall = this.$t('operationsvisitdetailtimeline.portcallupdated');
						detailsFormatted.push(`${this.visitTypeProperties[detail.type] || detail.type}: ${detail.value}`);
						if (detail.type === 'FROMPORTCODE') {
							milestone.additionalInfo = {
								icon: this.icons.warning.icon,
								icondesc: this.icons.warning.desc,
								iconSmall: true,
								color: this.icons.warning.color,
								dateFormatted: milestone.dateFormatted,
								detailsFormatted: ['Previous Port has changed']
							};
						}
					}
				}
			}

			milestone.detailsFormatted = detailsFormatted;
		},
		formatMilestoneStop(milestone) {
			const detailsFormatted = [];
			const stopNumber = this.getStopNumber(milestone.id);

			if (milestone.isCreation) {
				for (let i = 0, detailsLength = milestone.details.length; i < detailsLength; i++) {
					const detail = milestone.details[i];

					this.collectDataQualityCompliance(milestone, detail);

					if (this.eventsFromPortAuthority.includes(detail.type)) {
						milestone.icon = this.icons.portAuthority.icon;
						milestone.icondesc = this.icons.portAuthority.desc;
						milestone.color = this.icons.portAuthority.color;
					} else if (this.messagesFromTerminal.includes(detail.type)) {
						milestone.icon = this.icons.containerTerminal.icon;
						milestone.icondesc = this.icons.containerTerminal.desc;
						milestone.color = this.icons.containerTerminal.color;
						milestone.portcall = `${this.$t('operationsvisitdetailtimeline.berthupdated')} ${stopNumber}`;
					} else {
						milestone.icon = this.icons.shippingLine.icon;
						milestone.icondesc = this.icons.shippingLine.desc;
						milestone.color = this.icons.shippingLine.color;
					}

					if (this.stopExcludeProperties.includes(detail.type)) {
						// nothing to do but not remove
					} else if (typeof detail.value === 'string' && detail.value.match(dateTimeRegex)) {
						milestone.portcall = `${this.$t('operationsvisitdetailtimeline.berthupdated')} ${stopNumber}`;
						milestone.stoptype = this.getStopType(milestone.id, detail.type);
						const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(detail.value, this.dateTimeFormat);
						if (detail.type === 'ATA' || detail.type === 'ATATERMINAL') {
							detailsFormatted.push(`${this.$t('operationsvisitdetailtimeline.stoptypeata')} ${milestone.stoptype}`);
						} else {
							detailsFormatted.push(
								`${detail.type.replace('TERMINAL', '')} ${milestone.stoptype}: ${this.showAMPM(dateTimeFormatted)}`
							);
						}
					} else if (detail.value) {
						if (detail.value != 'DOCKED-NO_DETAILS') {
							detailsFormatted.push(`${this.stopTypeProperties[detail.type] || detail.type}: ${this.toCapitalFirst(detail.value)}`);
						}
						milestone.portcall = `${this.$t('operationsvisitdetailtimeline.berthupdated')} ${stopNumber}`;
					}
				}
			} else {
				for (let i = 0, detailsLength = milestone.details.length; i < detailsLength; i++) {
					const detail = milestone.details[i];

					this.collectDataQualityCompliance(milestone, detail);

					if (this.stopExcludeProperties.includes(detail.type)) {
						// nothing to do but not remove
					} else if (detail.type === 'ETC') {
						milestone.icon = this.icons.containerTerminal.icon;
						milestone.icondesc = this.icons.containerTerminal.desc;
						milestone.color = this.icons.containerTerminal.color;
						milestone.portcall = `${this.$t('operationsvisitdetailtimeline.berthupdated')} ${stopNumber}`;

						if (this.showAllVerbose === 0 || !this.firstEtcInserted) {
							const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(detail.value, this.dateTimeFormat);
							const diff = this.getDifference(
								this.visit.etd,
								this.$dateTimeUtils.getLocalFormattedDate(detail.value, this.diffDateTimeFormat)
							);
							const diffHours = this.formatDifference(diff);
							let diffDesc = 'of delay';
							let ms = this.$moment(
								this.$dateTimeUtils.getLocalFormattedDate(detail.value, this.diffDateTimeFormat),
								'DD/MM/YYYY HH:mm'
							).diff(this.$moment(this.visit.etd, this.ddMMyyyHHmmFormat));
							if (ms < 0) {
								diffDesc = 'in advance';
							}

							detailsFormatted.push(`ETC: ${this.showAMPM(dateTimeFormatted)}`);
							milestone.additionalInfo = {
								icon: this.icons.warning.icon,
								icondesc: this.icons.warning.desc,
								iconSmall: true,
								color: this.icons.warning.color,
								dateFormatted: milestone.dateFormatted,
								detailsFormatted: [`Cargo Operations will finish with ${diffHours} ${diffDesc}`]
							};

							if (!this.firstEtcInserted) {
								this.firstEtcInserted = true; // ya no inserta más, solo el primer evento ETC
							}
						}
					} else if (typeof detail.value === 'string' && detail.value.match(dateTimeRegex)) {
						milestone.portcall = `${this.$t('operationsvisitdetailtimeline.berthupdated')} ${stopNumber}`;
						milestone.stoptype = this.getStopType(milestone.id, detail.type);
						const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(detail.value, this.dateTimeFormat);

						if (this.milestoneReached.includes(detail.type)) {
							milestone.icon = this.icons.containerTerminal.icon;
							milestone.icondesc = this.icons.containerTerminal.desc;
							milestone.color = this.icons.containerTerminal.color;
							milestone.portcall = `${this.$t('operationsvisitdetailtimeline.berthupdated')} ${stopNumber}`;
							// hitos alcanzados
							milestone.additionalInfo = {
								icon: this.icons.eventLog.icon,
								icondesc: this.icons.eventLog.desc,
								iconSmall: true,
								color: this.icons.eventLog.color,
								dateFormatted: milestone.dateFormatted,
								detailsFormatted: [`${this.getEventDescription(detail.type)}`],
								portcall: 'Milestone Reached'
							};
						} else if (this.eventsFromPortAuthority.includes(detail.type)) {
							milestone.icon = this.icons.portAuthority.icon;
							milestone.icondesc = this.icons.portAuthority.desc;
							milestone.color = this.icons.portAuthority.color;
						} else if (this.messagesFromTerminal.includes(detail.type)) {
							milestone.icon = this.icons.containerTerminal.icon;
							milestone.icondesc = this.icons.containerTerminal.desc;
							milestone.color = this.icons.containerTerminal.color;
							milestone.portcall = `${this.$t('operationsvisitdetailtimeline.berthupdated')} ${stopNumber}`;
						} else {
							milestone.icon = this.icons.shippingLine.icon;
							milestone.icondesc = this.icons.shippingLine.desc;
							milestone.color = this.icons.shippingLine.color;
						}

						if (detail.type === 'ATA' || detail.type === 'ATATERMINAL') {
							detailsFormatted.push(`${this.$t('operationsvisitdetailtimeline.stoptypeata')} ${milestone.stoptype}`);
						} else {
							detailsFormatted.push(
								`${detail.type.replace('TERMINAL', '')} ${milestone.stoptype}: ${this.showAMPM(dateTimeFormatted)}`
							);
						}
					} else {
						milestone.portcall = this.$t('visit.statusid.rq.portcall');
						if (detail.type === 'BOLLARDINITERMINAL' || detail.type === 'BOLLARDENDTERMINAL') {
							let newvalue = !isNaN(detail.value) ? Math.ceil(detail.value) : '';
							detailsFormatted.push(`${this.stopTypeProperties[detail.type] || detail.type}: ${newvalue}`);
						} else if (detail.type === 'RTS') {
							detailsFormatted.push('Ready to Sail');
						} else if (detail.value && detail.value !== -1 && detail.value !== 'DOCKED-NO_DETAILS') {
							milestone.portcall = `${this.$t('operationsvisitdetailtimeline.berthupdated')} ${stopNumber}`;
							detailsFormatted.push(`${this.stopTypeProperties[detail.type] || detail.type}: ${this.toCapitalFirst(detail.value)}`);
						}

						if (this.eventsFromPortAuthority.includes(detail.type)) {
							milestone.icon = this.icons.portAuthority.icon;
							milestone.icondesc = this.icons.portAuthority.desc;
							milestone.color = this.icons.portAuthority.color;
						} else if (this.messagesFromTerminal.includes(detail.type)) {
							milestone.icon = this.icons.containerTerminal.icon;
							milestone.icondesc = this.icons.containerTerminal.desc;
							milestone.color = this.icons.containerTerminal.color;
							milestone.portcall = `${this.$t('operationsvisitdetailtimeline.berthupdated')} ${stopNumber}`;
						} else {
							milestone.icon = this.icons.shippingLine.icon;
							milestone.icondesc = this.icons.shippingLine.desc;
							milestone.color = this.icons.shippingLine.color;
						}
					}
				}
			}
			milestone.detailsFormatted = detailsFormatted;
		},
		formatMilestoneService(milestone) {
			const detailsFormatted = [];

			if (milestone.isCreation) {
				detailsFormatted.push(`Maritime Service (${milestone.type}) requested by Agent`); // TODO
				for (let i = 0, detailsLength = milestone.details.length; i < detailsLength; i++) {
					const detail = milestone.details[i];
					if (this.serviceExcludeProperties.includes(detail.type)) {
						// nothing to do
					} else if (typeof detail.value === 'string' && detail.value.match(dateTimeRegex)) {
						const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(detail.value, this.dateTimeFormat);
						detailsFormatted.push(`${this.serviceTypeProperties[detail.type] || detail.type}: ${dateTimeFormatted}`);
					} else if (detail.value) {
						detailsFormatted.push(`${this.serviceTypeProperties[detail.type] || detail.type}: ${detail.value}`);
					}
				}
			} else {
				for (let i = 0, detailsLength = milestone.details.length; i < detailsLength; i++) {
					const detail = milestone.details[i];
					if (detail.type === 'STATUS') {
						//detailsFormatted.push(`Maritime Service (${milestone.type}) ${detail.value.toLowerCase()} by Port Authority`);
					} else if (typeof detail.value === 'string' && detail.value.match(dateTimeRegex)) {
						const dateTimeFormatted = this.$dateTimeUtils.getLocalFormattedDate(detail.value, this.dateTimeFormat);
						detailsFormatted.push(`${milestone.type} ${this.serviceTypeProperties[detail.type] || detail.type}: ${dateTimeFormatted}`);
					} else {
						detailsFormatted.push(`${milestone.type} ${this.serviceTypeProperties[detail.type] || detail.type}: ${detail.value}`);
					}
				}
			}
			milestone.detailsFormatted = detailsFormatted;
		},
		formatMilestoneFromPort(milestone) {
			const detailsFormatted = [];
			detailsFormatted.push(`From port:  ${this.visit.fromportname} (${this.visit.fromportcode})`);
			milestone.detailsFormatted = detailsFormatted;
			milestone.icon = this.icons.shippingLine.icon;
			milestone.icondesc = this.icons.shippingLine.desc;
		},
		searchMilestones(searchText) {
			this.milestonesFiltered = [];
			this.milestonesNoDataMessage = null;
			const searchTextLowerCase = searchText ? searchText.toLowerCase() : '';

			for (let i = 0, milestonesLength = this.milestones.length; i < milestonesLength; i++) {
				const milestone = this.milestones[i];
				if (
					milestone.dateFormatted.includes(searchText) ||
					(milestone.portcall && milestone.portcall.toLowerCase().includes(searchTextLowerCase))
				) {
					this.milestonesFiltered.push(milestone);
					continue;
				}
				for (let j = 0, detailsLength = milestone.detailsFormatted.length; j < detailsLength; j++) {
					const detail = milestone.detailsFormatted[j];

					if (detail.toLowerCase().includes(searchTextLowerCase)) {
						this.milestonesFiltered.push(milestone);
						break;
					}
				}
			}

			if (this.toggleBtnFilterByIcon && this.toggleBtnFilterByIcon.length > 0) {
				var filterIcons = this.toggleBtnFilterByIcon;

				this.milestonesFiltered = this.milestonesFiltered.filter(function (elem) {
					return filterIcons.includes(elem.icon);
				});
			}

			if (this.milestonesFiltered.length === 0) {
				this.milestonesNoDataMessage = this.$t('operationsvisitdetailtimeline.noeventsfound');
			}
		},
		generateTextToPdf() {
			this.$store.state.global.puiloader.show = true;

			try {
				var doc = new jsPDF();
				doc.setFont('helvetica', 'bold');
				doc.setFontSize(20);
				doc.text(20, 20, `${this.visit.vesselname} - ${this.visit.portcallnumber} Timeline`);

				var numlinepage = 30;
				var numlinetotal = 1;
				for (let k = 0, arrDatesLength = this.arrDates.length; k < arrDatesLength; k++) {
					const date = this.arrDates[k];
					doc.setFont('helvetica', 'bold');
					doc.setFontSize(12);
					doc.text(20, numlinepage, `${this.getLongDate(date)}`);
					doc.line(20, numlinepage + 2, 60, numlinepage + 2); // horizontal line
					numlinepage += 7;
					numlinetotal++;
					for (let i = 0, milestonesLength = this.getMilestonesFilteredByDate(date).length; i < milestonesLength; i++) {
						const milestone = this.getMilestonesFilteredByDate(date)[i];
						doc.setFont('helvetica', 'bold');
						doc.setFontSize(10);
						doc.text(20, numlinepage, `${this.getLongTime(milestone.dateFormatted)} (${milestone.icondesc})`);
						numlinepage += 5;
						numlinetotal++;
						if (numlinetotal >= 52) {
							numlinetotal = 1;
							doc.addPage();
							numlinepage = 20;
						}
						for (let j = 0, detailsLength = milestone.detailsFormatted.length; j < detailsLength; j++) {
							const detail = milestone.detailsFormatted[j];
							doc.setFont('helvetica', 'normal');
							doc.setFontSize(10);
							doc.text(20, numlinepage, `${detail}`);
							numlinepage += 5;
							numlinetotal++;
							if (numlinetotal >= 52) {
								numlinetotal = 1;
								doc.addPage();
								numlinepage = 20;
							}
						}
					}
					doc.text(20, numlinepage, '');
					numlinepage += 5;
					numlinetotal++;
				}

				doc.save(`${this.visit.portcallnumber}_timeline.pdf`);
				this.$store.state.global.puiloader.show = false;
			} catch (e) {
				this.$store.state.global.puiloader.show = false;
				console.error(exception);
			}
		},
		addPreviousPortAtdMilestone(tempMilestones) {
			if (!this.previousPortAtdAdded && this.visit.atdfromportUtc) {
				const lastMilestone = tempMilestones.pop();
				if (this.visit.atdfromportUtc > lastMilestone.date) {
					this.previousPortAtdAdded = true;
					const dateFormatted = this.$dateTimeUtils.getLocalFormattedDate(this.visit.atdfromportUtc, this.dateTimeFormatMilestone);
					const port = this.$store.getters.getUserPortById(this.visit.portid);
					tempMilestones.push({
						icon: this.icons.warning.icon,
						icondesc: this.icons.warning.desc,
						iconSmall: true,
						color: this.icons.warning.color,
						dateFormatted: dateFormatted,
						detailsFormatted: [`Vessel has departed from previous Port to ${port}`]
					});
					tempMilestones.push({
						icon: this.icons.ais.icon,
						icondesc: this.icons.ais.desc,
						color: this.icons.ais.color,
						dateFormatted: dateFormatted,
						detailsFormatted: ['ATD Pilot Boarding Place from previous Port']
					});
				}
				tempMilestones.push(lastMilestone);
			}
		},
		afterFormatMilestone(tempMilestones) {
			const lastMilestone = tempMilestones.pop();
			if (lastMilestone.additionalInfo) {
				tempMilestones.push(lastMilestone.additionalInfo);
			}
			tempMilestones.push(lastMilestone);
		}
	}
};
</script>
