<template>
		<v-row dense>
			<v-col>
				<v-btn-toggle v-model="toggleBtnFilterByIcon" multiple mandatory>
					<div v-for="(detail, index) in toggleAllBtnIcons" :key="index">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn class="ma-1" :value="detail.icon" :active-class="setActiveClass(detail.icon)" small v-bind="attrs" v-on="on">
									<v-icon color="white" small>{{ detail.icon }}</v-icon>
								</v-btn>
							</template>
							<span>{{ getIconDescription(detail.icon) }}</span>
						</v-tooltip>
					</div>
				</v-btn-toggle>
			</v-col>
			<v-col style="text-align: right">
				<v-btn-toggle v-model="showAllVerbose">
					<div>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn class="ma-1" :active-class="showAllVerbose === 0 ? 'verboseSelected' : ''" small v-bind="attrs" v-on="on">
									<v-icon small>fab fa-vimeo-v</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('operationsvisitdetailtimeline.toolbar.verboseBtn') }}</span>
						</v-tooltip>
					</div>
				</v-btn-toggle>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="ma-1" small @click.stop="generateTextToPDF()" v-bind="attrs" v-on="on">
							<v-icon small>far fa-file-download</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('operationsvisitdetailtimeline.toolbar.downloadPdfBtn') }}</span>
				</v-tooltip>
			</v-col>
		</v-row>
</template>

<script>
import TimelineMixin from '@/mixins/TimelineMixin';

export default {
	mixins: [TimelineMixin],
	data() {
		return {
			toggleBtnFilterByIcon: ['fas fa-forklift', 'fas fa-ship', 'fas fa-trophy', 'fas fa-exclamation', 'fas fa-home-alt', 'fas fa-broadcast-tower'],
			toggleAllBtnIcons: [
				{ icon: 'fas fa-forklift' },
				{ icon: 'fas fa-ship' },
				{ icon: 'fas fa-trophy' },
				{ icon: 'fas fa-exclamation' },
				{ icon: 'fas fa-home-alt' },
				{ icon: 'fas fa-broadcast-tower' }
			],
			showAllVerbose: undefined
		};
	},
	watch: {
		toggleBtnFilterByIcon(newVal) {
			this.$emit('toggleBtnFiltered', newVal);
		},
		showAllVerbose: {
			handler(newVal) {
				this.$emit('verboseSelected', newVal);
			}
		}
	},
	methods: {
		generateTextToPDF() {
			this.$emit('generatePDFClicked', true);
		},
		setActiveClass(_icon) {
			const icon = _icon ? _icon : '';
			if (icon.includes('ship')) return 'shipSelected';
			else if (icon.includes('home')) return 'homeSelected';
			else if (icon.includes('trophy')) return 'trophySelected';
			else if (icon.includes('exclamation')) return 'exclamationSelected';
			else if (icon.includes('forklift')) return 'forklitSelected';
			else if (icon.includes('broadcast')) return 'broadcastSelected';
			else return '';
		},
		getIconDescription(_icon) {
			const icon = _icon ? _icon : '';
			if (icon == this.icons.shippingLine.icon) return this.icons.shippingLine.desc;
			else if (icon == this.icons.portAuthority.icon) return this.icons.portAuthority.desc;
			else if (icon == this.icons.containerTerminal.icon) return this.icons.containerTerminal.desc;
			else if (icon == this.icons.warning.icon) return this.icons.warning.desc;
			else if (icon == this.icons.eventLog.icon) return this.icons.eventLog.desc;
			else if (icon == this.icons.ais.icon) return this.icons.ais.desc;
			else return this.icons.others.desc;
		}
	}
}
</script>
