import Pui9HttpRequests from 'pui9-requests';
// PUI9 DEBUG
// import Pui9HttpRequests from '../../../../pui9/pui9-client/es.prodevelop.pui9.client.libraries/pui9-requests/src';

const externalService = {
	/**
	 * Get vessel information object from the AIS provider.
	 *
	 * @returns vesselInfo (Object)
	 */
	getVesselInfo: async function (vesselImo) {
		try  {
			const { data: { ais } = {} } = await Pui9HttpRequests.getRequest('/vtexplorer/info?imo=' + vesselImo);
			return ais;
		} catch(err) {
			console.error(err);
		}
	},

	/**
	 * Get shortest sea route between vessel and port location.
	 *
	 * @returns Length in meters of calculated route (Number)
	 */
	getDistance: async function(fromLonLat, toLonLat) {
		try  {
			const { data: distance = undefined } = await Pui9HttpRequests.getRequest('/vtexplorer/distance?from=' + fromLonLat + '&to=' + toLonLat);
			return distance;
		} catch(err) {
			console.error(err);
		}
	},
	/**
	 * Get vessel last event (ARRIVAL/DEPARTURE) using external service (https://api.vtexplorer.com/docs/portcalls.html)
	 *
	 * @returns lastEvent (Object)
	 */
	getVesselLastEvent: async function (vesselImo) {
		try {
			const { data: { portcall, ais } = {} } = await Pui9HttpRequests.getRequest('/vtexplorer/portcall?imo=' + vesselImo);
			if (!portcall && !ais) {
				return null;
			}

			if (portcall.event === 'ARRIVAL') {
				portcall.navstatdesc = 'Moored';
				return portcall;
			} else if (portcall.event === 'DEPARTURE') {
				portcall.speed = ais.speed;
				portcall.destination = ais.destination;
				switch (ais.navstat) {
					case 0:
						portcall.navstatdesc = 'Sailing';
						break;
					case 1:
						portcall.navstatdesc = 'Anchoring';
						break;
					case 5:
						portcall.navstatdesc = 'Moored';
						break;
					case 8:
						portcall.navstatdesc = 'Sailing';
						break;
				}

				return portcall;
			}

			return null;
		} catch(err) {
			console.error(err);
		}
	}
};

export default externalService;
