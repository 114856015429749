<template>
	<v-container class="pt-3 pr-0 pb-3 pl-0">
		<operations-bar-chart :chartData="chartData"></operations-bar-chart>
		<operations-chart-legend :chartData="chartData"></operations-chart-legend>
	</v-container>
</template>

<script>
import OperationsBarChart from '../OperationsBarChart.vue';
import OperationsChartLegend from '../OperationsChartLegend.vue';
import DifferenceMixin from '@/mixins/HHmmDifferenceMixin';
import { color as am4core_color } from '@amcharts/amcharts4/core';

export default {
	components: { OperationsBarChart, OperationsChartLegend },
	mixins: [DifferenceMixin],
	props: {
		visit: {
			type: Object,
			required: true
		},
		stopSelected: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			chartData: []
		};
	},
	watch: {
		stopSelected() {
			this.fillChartData(this.stopSelected);
		}
	},
	mounted() {
		this.fillChartData(this.stopSelected);
	},
	methods: {
		fillChartData(stop) {
			this.chartData = [];

			// Pilot Process Arrival = PBA – APS
			const ppaId = 'PPA';
			const ppaName = this.$t('operationsvisitdetailoverview.ppa.name');
			const ppaTooltip = this.$t('operationsvisitdetailoverview.ppa.tooltip');
			const ppaValue = this.getDifference(this.visit.aps, stop.pba);
			const ppaMinutes = this.getMinutes(ppaValue);
			//console.log(ppaName, ppaValue, ppaMinutes);

			// Pilot Berthing Process = AFA – PBA
			const pbpId = 'PBP';
			const pbpName = this.$t('operationsvisitdetailoverview.pbp.name');
			const pbpTooltip = this.$t('operationsvisitdetailoverview.pbp.tooltip');
			const pbpValue = this.getDifference(stop.pba, stop.afa);
			const pbpMinutes = this.getMinutes(pbpValue);
			//console.log(pbpName, pbpValue, pbpMinutes);

			// Cargo Operations Preparation Process = FCL – AFA
			const coppId = 'COPP';
			const coppName = this.$t('operationsvisitdetailoverview.copp.name');
			const coppTooltip = this.$t('operationsvisitdetailoverview.copp.tooltip');
			const coppValue = this.getDifference(stop.afa, stop.fcl);
			const coppMinutes = this.getMinutes(coppValue);
			//console.log(coppName, coppValue, coppMinutes);

			// Cargo Operations Process = LCL - FCL
			const copId = 'COP';
			const copName = this.$t('operationsvisitdetailoverview.cop.name');
			const copTooltip = this.$t('operationsvisitdetailoverview.cop.tooltip');
			const copValue = this.getDifference(stop.fcl, stop.lcl);
			const copMinutes = this.getMinutes(copValue);
			//console.log(copName, copValue, copMinutes);

			// Cargo Operations Closure Process = RTS - LCL
			const cocpId = 'COCP';
			const cocpName = this.$t('operationsvisitdetailoverview.cocp.name');
			const cocpTooltip = this.$t('operationsvisitdetailoverview.cocp.tooltip');
			const cocpValue = this.getDifference(stop.lcl, stop.rts);
			const cocpMinutes = this.getMinutes(cocpValue);
			//console.log(cocpName, cocpValue, cocpMinutes);

			// Pilot Process Departure = PBD – RTS
			const ppdId = 'PPD';
			const ppdName = this.$t('operationsvisitdetailoverview.ppd.name');
			const ppdTooltip = this.$t('operationsvisitdetailoverview.ppd.tooltip');
			const ppdValue = this.getDifference(stop.rts, stop.pbd);
			const ppdMinutes = this.getMinutes(ppdValue);
			//console.log(ppdName, ppdValue, ppdMinutes);

			// Pilot Unberthing Process = POF – PBD
			const pupId = 'PUP';
			const pupName = this.$t('operationsvisitdetailoverview.pup.name');
			const pupTooltip = this.$t('operationsvisitdetailoverview.pup.tooltip');
			const pupValue = this.getDifference(stop.pbd, this.visit.pof);
			const pupMinutes = this.getMinutes(pupValue);
			//console.log(pupName, pupValue, pupMinutes);

			this.chartData = [
				{ id: ppaId, name: ppaName, tooltip: ppaTooltip, time: this.formatDifference(ppaValue), minutes: ppaMinutes, color: am4core_color('#b758d9') },
				{ id: pbpId, name: pbpName, tooltip: pbpTooltip, time: this.formatDifference(pbpValue), minutes: pbpMinutes, color: am4core_color('#b758d9') },
				{ id: coppId, name: coppName, tooltip: coppTooltip, time: this.formatDifference(coppValue), minutes: coppMinutes, color: am4core_color('#b758d9') },
				{ id: copId, name: copName, tooltip: copTooltip, time: this.formatDifference(copValue), minutes: copMinutes, color: am4core_color('#4fa439') },
				{ id: cocpId, name: cocpName, tooltip: cocpTooltip, time: this.formatDifference(cocpValue), minutes: cocpMinutes, color: am4core_color('#ef7217') },
				{ id: ppdId, name: ppdName, tooltip: ppdTooltip, time: this.formatDifference(ppdValue), minutes: ppdMinutes, color: am4core_color('#ef7217') },
				{ id: pupId, name: pupName, tooltip: pupTooltip, time: this.formatDifference(pupValue), minutes: pupMinutes, color: am4core_color('#ef7217') }
			];
		},
		getMinutes(value) {
			if (value) {
				const [hours, minutes] = value.split(':');
				const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
				return totalMinutes;
			}
			return 0;
		}
	}
};
</script>
