<template>
	<v-layout wrap ma-4>
		<v-flex xs12 v-for="(data, index) in chartData" :key="index">
			<div class="operations-legend__block">
				<div class="circle" :style="{ background: data.color }"></div>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<div v-bind="attrs"	v-on="on">
							<label>{{ data.id }} ({{ data.name }})</label>
							<span v-if="data.time">: {{ data.time }}</span>
							<span v-else>: {{ $t('operationsdatatable.unknown') }}</span>
						</div>
					</template>
					<span>{{ data.tooltip }}</span>
				</v-tooltip>
			</div>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	props: {
		chartData: {
			type: Array,
			required: true
		}
	}
};
</script>
