<template>
	<v-menu v-model="menu" :close-on-content-click="false" offset-y>
		<template v-slot:activator="{ on: menuActivator }">
			<v-tooltip top :z-index="200">
				<template v-slot:activator="{ on: tooltip }">
					<v-icon v-on="{ ...tooltip, ...menuActivator }" small v-if="!edited">fas fa-edit</v-icon>
					<v-icon v-on="{ ...tooltip, ...menuActivator }" small v-else color="red accent-4">fas fa-edit</v-icon>
				</template>
				<span>{{ $t('operationsvisitedit.tooltip') }}</span>
			</v-tooltip>
		</template>

		<v-card class="operations-visit-edit" :max-width="400">
			<v-card-title>{{ $t('operationsvisitedit.edit.title') }}</v-card-title>

			<operations-visit-edit-date :label="$t('operationsvisitedit.edit.etapbp')" :date.sync="model.eta" />

			<div v-for="(stop, index) in stopsToEdit" :key="index">
				<operations-visit-edit-date :label="`${$t('operationsvisitedit.edit.etaberth')} (${stop.location})`" :date.sync="stop.etaToEdit" />
			</div>

			<v-divider />

			<v-card-actions>
				<v-spacer />

				<v-btn text @click="close()">{{ $t('form.cancel') }}</v-btn>
				<v-btn color="primary" text @click="save()">{{ $t('form.save') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
import OperationsVisitEditDate from './OperationsVisitEditDate.vue';

export default {
	components: { OperationsVisitEditDate },
	props: {
		visit: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			menu: false,
			model: {
				eta: null
			},
			localFormat: 'DD/MM/YYYY HH:mm'
		};
	},
	computed: {
		edited() {
			if (this.visit.changes && this.visit.changes.eta && this.visit.changes.eta.value) {
				return true;
			} else {
				return (
					this.visit.stops.filter((stop) => {
						return stop.changes && stop.changes.eta && stop.changes.eta.value;
					}).length > 0
				);
			}
		},
		stopsToEdit() {
			return this.visit.stops.filter((stop) => {
				if (!stop.isanchorage && stop.status !== 'CANCELLED') {
					stop.etaToEdit = this.$moment(stop.eta, this.localFormat).format();
					return true;
				}
				return false;
			});
		}
	},
	watch: {
		menu(opened) {
			if (opened) {
				if (this.edited) {
					this.model.eta = this.visit.changes.eta.value;
				} else if (this.visit.eta) {
					const etaFormatted = this.$moment(this.visit.eta, this.localFormat).format();
					this.model.eta = etaFormatted;
				} else {
					this.model.eta = this.$dateTimeUtils.getUTCDateNow();
				}
			}
		}
	},
	methods: {
		close() {
			this.menu = false;
		},
		save() {
			let changes = false;
			if (this.model.eta && this.model.eta !== 'Invalid date') {
				const url = this.$store.getters.getModelByName('visit').url.patch;
				const params = { eta: this.model.eta };
				const pk = { id: this.visit.id };
				this.doPatch(url, params, pk);
				changes = true;
			}

			const stopUrl = this.$store.getters.getModelByName('stop').url.patch;
			for (let i = 0, stopsToEditLength = this.stopsToEdit.length; i < stopsToEditLength; i++) {
				const stopToEdit = this.stopsToEdit[i];
				if (stopToEdit.etaToEdit && stopToEdit.etaToEdit !== 'Invalid date') {
					const stopParams = { eta: stopToEdit.etaToEdit };
					const stopPk = { id: stopToEdit.id };
					this.doPatch(stopUrl, stopParams, stopPk);
					changes = true;
				}
			}

			if (changes) {
				this.$puiNotify.success(this.$t('pui9.save.success'));
				this.$puiEvents.$emit('operationscountdown_reload');
			}

			this.menu = false;
		},
		doPatch(url, params, pk) {
			this.$puiRequests.patchRequest(
				url,
				params,
				null,
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				},
				pk
			);
		}
	}
};
</script>

<style lang="postcss">
.operations-visit-edit {
	& span {
		&.edit__title {
			font-weight: 600;
			font-size: 15px;
		}
	}
	& .v-btn {
		min-width: 50px;
	}
}
</style>
