<template>
	<div>
		<v-data-table :headers="currentHeaders" :items="current" hide-default-footer>
			<template v-slot:item.departureIdleTimeFormatted="{ item }">
				<v-chip :color="item.departureIdleTimeColor" label dark v-if="item.departureIdleTimeFormatted">{{
					item.departureIdleTimeFormatted
				}}</v-chip>
			</template>
		</v-data-table>
		<v-toolbar dense flat>
			<v-toolbar-title>{{ $t('operationsvisitidletime.title.history') }}</v-toolbar-title>
		</v-toolbar>
		<v-data-table :headers="stopHeaders" :items="stops" hide-default-footer>
			<template v-slot:item.departureIdleTimeFormatted="{ item }">
				<v-chip :color="item.departureIdleTimeColor" label dark>{{ item.departureIdleTimeFormatted }}</v-chip>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import DifferenceMixin from '@/mixins/HHmmDifferenceMixin';

export default {
	mixins: [DifferenceMixin],
	props: {
		visit: {
			type: Object,
			required: true
		},
		stop: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			currentHeaders: [
				{ text: this.$t('operationsvisitidletime.event'), value: 'event' },
				{ text: this.$t('operationsvisitidletime.datetime'), value: 'datetime' },
				{ text: this.$t('operationsvisitidletime.difference'), value: 'difference', align: 'center' },
				{ text: this.$t('operationsvisitidletime.departureidletime'), value: 'departureIdleTimeFormatted', align: 'center' }
			],
			current: [],
			stopHeaders: [
				{ text: this.$t('operationsvisitidletime.ata'), value: 'ata' },
				{ text: this.$t('operationsvisitidletime.atd'), value: 'atd' },
				{ text: this.$t('RTS - ATC'), value: 'diffRtsAtcFormatted', align: 'center' },
				{ text: this.$t('operationsvisitidletime.departureidletime'), value: 'departureIdleTimeFormatted', align: 'center' }
			],
			stops: []
		};
	},
	async created() {
		this.calculateDifferences(this.stop);
		this.fillCurrent(this.stop);
		this.$store.state.global.puiloader.show = true;
		this.stops = await this.getStopsByVesselImo(this.visit.vesselimo, this.visit.portcallnumber);
		this.$store.state.global.puiloader.show = false;
	},
	methods: {
		calculateDifferences(stop) {
			stop.diffLclFcl = this.getDifference(stop.fcl, stop.lcl);
			stop.diffLclFclFormatted = this.formatDifference(stop.diffLclFcl);
			stop.diffRtsLcl = this.getDifference(stop.rts, stop.lcl);
			stop.diffRtsLclFormatted = this.formatDifference(stop.diffRtsLcl);
			stop.diffPbdRts = this.getDifference(stop.pbd, stop.rts);
			stop.diffPbdRtsFormatted = this.formatDifference(stop.diffPbdRts);
			stop.diffAtdPbd = this.getDifference(stop.atd, stop.pbd);
			stop.diffAtdPbdFormatted = this.formatDifference(stop.diffAtdPbd);
			stop.departureIdleTime = this.getDifference(stop.atd, stop.lcl);
			stop.departureIdleTimeFormatted = this.formatDifference(stop.departureIdleTime);
			const [hours, minutes] = stop.departureIdleTime.split(':');
			const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
			stop.departureIdleTimeColor = totalMinutes < 45 ? 'green' : 'red';
		},
		fillCurrent(stop) {
			this.current = [];
			this.current.push({ event: this.$t('operationsvisitidletime.fcl'), datetime: stop.fcl });
			this.current.push({ event: this.$t('operationsvisitidletime.lcl'), datetime: stop.lcl, difference: stop.diffLclFclFormatted });
			this.current.push({ event: this.$t('operationsvisitidletime.rts'), datetime: stop.rts, difference: stop.diffRtsLclFormatted });
			this.current.push({
				event: this.$t('operationsvisitidletime.atd'),
				datetime: stop.atd,
				difference: stop.diffAtdPbdFormatted,
				departureIdleTimeFormatted: stop.departureIdleTimeFormatted,
				departureIdleTimeColor: stop.departureIdleTimeColor
			});
		},
		async getStopsByVesselImo(vesselimo, portcallnumber) {
			const opts = {
				model: 'visit',
				page: 1,
				rows: 6,
				order: [{ column: 'eta', direction: 'desc' }],
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'vesselimo', op: 'eq', data: vesselimo },
						{ field: 'portcallnumber', op: 'ne', data: portcallnumber },
						{ field: 'ata', op: 'nn', data: null },
						{ field: 'atd', op: 'nn', data: null }
					]
				}
			};
			const response = await this.$puiRequests.postRequest('/puisearch', opts);
			const stops = [];

			for (let i = 0, visitsLength = response.data.data.length; i < visitsLength; i++) {
				const visit = JSON.parse(JSON.stringify(response.data.data[i]));

				const visitStops = visit.stops.filter((stop) => !stop.isanchorage && stop.departureidletime);
				if (visitStops && visitStops.length === 1) {
					const stop = visitStops[0];
					//this.convertDatesFromStop(stop);
					stop.diffRtsAtc = this.getDifference(stop.atc, stop.rts);
					stop.diffRtsAtcFormatted = this.formatDifference(stop.diffRtsAtc);
					if (stop.departureidletime) {
						stop.departureIdleTimeFormatted = this.formatDifference(stop.departureidletime);
						const [hours, minutes] = stop.departureidletime.split(':');
						const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
						stop.departureIdleTimeColor = totalMinutes < 45 ? 'green' : 'red';
					}

					stops.unshift(stop);
				}
			}

			return stops;
		},
		convertDatesFromStop(stop) {
			if (stop.ata) {
				stop.ata = this.$dateTimeUtils.getLocalFormattedDate(stop.ata, this.ddMMyyyHHmmFormat);
			}
			if (stop.atd) {
				stop.atd = this.$dateTimeUtils.getLocalFormattedDate(stop.atd, this.ddMMyyyHHmmFormat);
			}
			if (stop.atc) {
				stop.atc = this.$dateTimeUtils.getLocalFormattedDate(stop.atc, this.ddMMyyyHHmmFormat);
			}
			if (stop.rts) {
				stop.rts = this.$dateTimeUtils.getLocalFormattedDate(stop.rts, this.ddMMyyyHHmmFormat);
			}
		}
	}
};
</script>
